import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query ExploreFilters {
    productTypes
  }
`)

export const mockExploreFiltersData = mockData<Data>({
  productTypes: ["skill", "process", "act"],
})

registerMockData(query, mockExploreFiltersData())
