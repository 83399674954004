import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query ThemeProvider {
    currentCompany {
      companyStarColor
      companyButtonColor
    }
  }
`)

export const mockThemeProviderData = mockData<Data>({
  currentCompany: {
    companyStarColor: "#c469e3;",
    companyButtonColor: "#651cf8",
  },
})

registerMockData(query, mockThemeProviderData())
