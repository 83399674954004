import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { Deadline } from "../../../Deadline"
import type { Data } from "../gql"
import s from "./styles.module.scss"

/**
 * Shows a deadline status of a product, if it exists.
 * Should be used for products that do not have diplomas.
 * For products with diplomas, use `DiplomaStatus` component.
 */
export const DeadlineStatus = ({ product }: { product: Data }) => {
  const deadlineStatus = product?.deadlineOccurrence?.status
  const date = product?.deadlineOccurrence?.deadlineDate

  const getDeadlineStatus = () => {
    switch (deadlineStatus) {
      case "due":
        return <Deadline variant="dueDate" size="medium" deadline={date} />
      case "overdue":
        return <Deadline variant="overdue" size="medium" deadline={date} />
      case "certified":
      case "certified_after_deadline":
        return <CertifiedStatus />
    }
  }

  return <div className={s.statusWrapper}>{getDeadlineStatus()}</div>
}

const CertifiedStatus = () => {
  const { t } = useTranslation()

  return (
    <div className={modifiers(s, "certifiedStatus", { isCertified: true })}>
      <div className={s.certifiedStatus__caption}>{t("product.CERTIFIED")}</div>
    </div>
  )
}
