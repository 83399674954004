import { parseSearchWith } from "@tanstack/react-location"

/**
 * This is a fix to how @tanstack/react-location decoded search params.
 *
 * React Location parses and serializes search params automatically. It has its own
 * implementation as opposed to URLSearchParams to handle non-string search params:
 *
 * https://tanstack.com/router/latest/docs/framework/react/guide/search-params#why-not-just-use-urlsearchparams
 *
 * But there seems to be a bug in implementation where it doesn't decode '+' as space
 * but treats it as a literal character, while at this stage "search" argument still
 * has + sign instead of a whitespace.
 *
 * Consider the following situation:
 *
 * - search param string is: ?test=two%2Btwo+equals+four (%2B is a literal "+" char)
 * - ReactLocation uses its default parseSearchWith implementation, which attempts to
 *   decode the search param string:
 *   https://github.com/TanStack/router/blob/react-location/packages/react-location/src/index.tsx#L2043
 * - %2B gets decoded to "+", but the + sign is not decoded to a whitespace
 * - instead of "two+two equals four" we get "two+++two+equals+four"
 *
 * We fix it by overwriting their default parsing implementation with our own
 * and just replacing all pluses (which at this stage represent whitespaces) with
 * actual encoded whitespace
 *
 * https://web.archive.org/web/20230127003641/https://react-location.tanstack.com/guides/custom-search-param-serialization
 *
 * All this wall of text and the actual function is just two lines long :D
 *
 */
export const parseSearch = (search: string) => {
  const encodedSearchStr = search.replace(/\+/g, "%20")
  return parseSearchWith(JSON.parse)(encodedSearchStr)
}
