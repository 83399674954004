import { Dialog } from "materia"
import { useTranslation } from "react-i18next"
import { getStaticLauncherLink } from "../../../tools/getStaticLauncherLink"
import s from "./styles.module.scss"

export const LauncherOverlay = ({ onBack }: { onBack: () => void }) => {
  const { t } = useTranslation()

  return (
    <Dialog ariaLabel="Play in Attensi Launcher" onClose={onBack}>
      <div className={s.launcherOverlay}>
        <div>{t("product.STARTSIM_DESC")}</div>
        <div>
          {t("settings.DOWNLOAD_LAUNCHER_PROMPT")}{" "}
          <a className={s.launcherOverlay__link} href={getStaticLauncherLink()}>
            {t("settings.DOWNLOAD_LAUNCHER_PROMPT_2")}
          </a>
        </div>
        <button onClick={onBack}>&lt; {t("product.STARTSIM_DESC_BACK")}</button>
      </div>
    </Dialog>
  )
}
