import avatar from "assets/demo/avatar.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type RecentActivityEvent = Data["leaderboard"]["nodes"][number]

export const query = graphql(`
  query RecentActivityWidgetModal {
    leaderboard: recentActivityEvents(limit: 1, eventType: leaderboard) {
      nodes {
        ...Event
      }
    }
    completed: recentActivityEvents(limit: 1, eventType: completed) {
      nodes {
        ...Event
      }
    }
    stars: recentActivityEvents(limit: 1, eventType: stars) {
      nodes {
        ...Event
      }
    }
  }

  fragment Event on Event {
    id
    createdAt
    eventType
    rank
    stars
    product {
      title
    }
    user {
      avatar
      avatarTitle
      displayName
    }
  }
`)

export const mockRecentActivityWidgetModalData = mockData<Data>({
  stars: {
    nodes: [
      {
        createdAt: "",
        eventType: "stars",
        id: "1",
        product: {
          title: "Product Title",
        },
        rank: 21,
        stars: 3,
        user: {
          avatar: avatar,
          avatarTitle: "Fancy avatar title",
          displayName: "Full Name",
        },
      },
    ],
  },
  leaderboard: {
    nodes: [
      {
        createdAt: "",
        eventType: "leaderboard",
        id: "1",
        product: {
          title: "Product Title",
        },
        rank: 21,
        stars: 3,
        user: {
          avatar: avatar,
          avatarTitle: "Fancy title",
          displayName: "Full Name",
        },
      },
    ],
  },
  completed: {
    nodes: [
      {
        createdAt: "",
        eventType: "completed",
        id: "1",
        product: {
          title: "Product Title",
        },
        rank: 21,
        stars: 3,
        user: {
          avatar: avatar,
          avatarTitle: "Fancy title",
          displayName: "Full Name",
        },
      },
    ],
  },
})

registerMockData(query, mockRecentActivityWidgetModalData())
