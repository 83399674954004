import { useScrollToTopOnMount } from "hooks"
import { useWindowWidth } from "materia"
import { useTranslation } from "react-i18next"
import { usePageTitle } from "../../tools/usePageTitle"
import { LeaderboardViewCompactLoader } from "./LeaderboardViewCompact/LeaderboardViewCompactLoader"
import { LeaderboardViewLoader } from "./LeaderboardViewLoader"

export const LeaderboardViewResponsive = () => {
  useScrollToTopOnMount()

  const { t } = useTranslation()
  usePageTitle(t("pageTitle.LEADERBOARDS"))

  const { isSmallWindow } = useWindowWidth()

  return isSmallWindow ? (
    <LeaderboardViewCompactLoader />
  ) : (
    <LeaderboardViewLoader />
  )
}
