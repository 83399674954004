import { auth } from "auth"
import { exists } from "utils"
import { graphql, graphqlRequest } from "../graphql"
import { pickLocale } from "./pickLocale"

const query = graphql(`
  query PickLocale {
    currentCompany {
      defaultLocale
      portalSupportedLocales
    }

    currentUser {
      portalLocale
    }
  }
`)

export const pickLocaleForCurrentCompany = async () => {
  const session = await auth.getActiveSession()

  const data = await graphqlRequest({ query, variables: {} })

  const { currentCompany, currentUser } = data
  const { portalLocale } = currentUser
  const { portalSupportedLocales, defaultLocale } = currentCompany
  const allowedLocales = portalSupportedLocales
  const browserLocales = window.navigator.languages
  const sessionLocale = session.pickedLocale

  if (isLocaleValid(portalLocale, allowedLocales)) {
    return portalLocale
  }

  if (isLocaleValid(sessionLocale, allowedLocales)) {
    return sessionLocale
  }

  return pickLocale({
    defaultLocale,
    allowedLocales,
    browserLocales,
  })
}

const isLocaleValid = (
  locale: string | undefined | null,
  allowedLocales: string[]
): locale is string => {
  return exists(locale) && allowedLocales.includes(locale)
}
