import { createContext } from "react"

export type GraphQLEnvironment = {
  baseUrl: string
  path: string
}

export const EnvironmentContext = createContext<GraphQLEnvironment | undefined>(
  undefined
)
