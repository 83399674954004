import { useStableCallback } from "hooks"
import { useEffect } from "react"
import { assert, literal, type, type Infer } from "superstruct"

type Message = Infer<typeof IncomingMessage>

const IncomingMessage = type({
  type: literal("EXIT_MODAL"),
})

export const useIframeMessages = ({
  onMessage,
  targetUrl,
}: {
  onMessage: (message: Message) => void
  targetUrl: string
}) => {
  const stableOnMessage = useStableCallback(onMessage)

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      messageListener(event, stableOnMessage, targetUrl)
    }

    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [stableOnMessage, targetUrl])
}

const messageListener = (
  event: MessageEvent,
  onMessage: (message: Message) => void,
  targetUrl: string
) => {
  if (!isEventValid(event, targetUrl)) return

  const data = parseIncomingMessage(event.data)

  onMessage(data)
}

const isEventValid = (event: MessageEvent, targetUrl: string) => {
  const eventOrigin = event.origin
  const targetOrigin = new URL(targetUrl).origin

  return eventOrigin === targetOrigin
}

const parseIncomingMessage = (data: unknown) => {
  assert(data, IncomingMessage)
  return data
}
