import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query NewsView {
    newsPosts {
      nodes {
        body
        headline
        id
        image
        isLiked
        likeCount
        updatedAt
      }
    }
  }
`)

export const mockNewsViewData = mockData<Data>({
  newsPosts: {
    nodes: times(3, (index) => ({
      body: "Click here to see the video",
      headline: "Attensi Music Video",
      id: index.toString(),
      image: productImage,
      isLiked: false,
      likeCount: 15,
      updatedAt: new Date().toISOString(),
    })),
  },
})

registerMockData(query, mockNewsViewData())
