import { FeaturedProductsLoading } from "./FeaturedProducts/loading"
import { JourneyTeaserLoading } from "./JourneyTeaser/loading"
import { MobileWidgetsLoading } from "./MobileWidgets/loading"
import { ProductCategoriesLoading } from "./ProductCategories/loading"
import s from "./styles.module.scss"

export const ForYouViewLoading = () => {
  return (
    <div className={s.loading}>
      <FeaturedProductsLoading />
      <JourneyTeaserLoading />
      <MobileWidgetsLoading />
      <ProductCategoriesLoading count={3} />
    </div>
  )
}
