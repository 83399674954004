import { Card, ErrorMessage } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const NewsWidgetMobileError = () => {
  const { t } = useTranslation()
  return (
    <Card borderRadius="24">
      <div className={s.error}>
        <ErrorMessage
          title={t("error.GENERIC")}
          message={t("error.NEWS_WIDGET_FETCH_ERROR")}
        />
      </div>
    </Card>
  )
}
