import { useEffect, useRef } from "react"

export const useHasValueChanged = <
  T extends boolean | string | number | undefined
>(
  value: T
) => {
  const enableAnimations = useRef(false)
  const initialValue = useRef(value)
  const hasChanged = initialValue.current !== value

  useEffect(() => {
    if (hasChanged) {
      enableAnimations.current = true
    }
  }, [hasChanged])

  return enableAnimations.current || hasChanged
}
