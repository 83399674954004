import { useGraphqlQuery } from "graphql-mock"
import { type SetQueryParam } from "hooks"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup } from "utility-components"
import type { ProductTypeIdentifier } from "../../../graphql"
import { query } from "./gql"
import { ExploreFiltersLoading } from "./loading"
import s from "./styles.module.scss"

export const ExploreFilters = ({
  productType,
  setActiveType,
}: {
  productType: ProductTypeIdentifier | undefined
  setActiveType: SetQueryParam<ProductTypeIdentifier>
}) => {
  const { t } = useTranslation()

  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <ExploreFiltersLoading />
  if (error) return <ErrorPopup error={error} />

  return (
    <div className={s.filters} role="group">
      {data.productTypes.map((type) => (
        <Filter
          key={type}
          isActive={productType === type}
          onClick={() => setActiveType(type === productType ? undefined : type)}
          text={t(`product.PRODUCT_TYPES.${type}`)}
        />
      ))}
    </div>
  )
}

const Filter = ({
  isActive,
  onClick,
  text,
}: {
  isActive: boolean
  onClick: () => void
  text: string
}) => {
  return (
    <button className={modifiers(s, "filter", { isActive })} onClick={onClick}>
      {text}
    </button>
  )
}
