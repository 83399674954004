import {
  useInfiniteQuery,
  type InfiniteData,
  type QueryFunction,
  type QueryKey,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
} from "@tanstack/react-query"
import { merge } from "lodash-es"
import type { FnBase, FnParams, FnReturn } from "./types"
import { getArgs } from "./utils/getArgs"

type PartialArray<T extends readonly any[]> = {
  readonly [P in keyof T]?: T[P] extends Record<any, any> ? Partial<T[P]> : T[P]
}

type BaseInitialData<Fn extends FnBase> =
  | InfiniteData<FnReturn<Fn>>
  | (() => InfiniteData<FnReturn<Fn>>)
  | undefined

type Options<Fn extends FnBase, TData, InitialData> = Omit<
  UseInfiniteQueryOptions<FnReturn<Fn>, Error, TData>,
  | "queryFn"
  | "queryKey"
  | "select"
  | "initialData"
  | "getNextPageParam"
  | "getPreviousPageParam"
> & {
  initialData?: InitialData
  getNextPageParam?: (
    lastPage: FnReturn<Fn>,
    allPages: FnReturn<Fn>[]
  ) => PartialArray<FnParams<Fn>> | undefined
  getPreviousPageParam?: (
    firstPage: FnReturn<Fn>,
    allPages: FnReturn<Fn>[]
  ) => PartialArray<FnParams<Fn>> | undefined
  select?: (data: FnReturn<Fn>) => TData
}

export type UseInfiniteQuery<Fn extends FnBase> = <
  TData = FnReturn<Fn>,
  InitialData extends BaseInitialData<Fn> = undefined
>(
  args: FnParams<Fn>,
  options?: Options<Fn, TData, InitialData>
) => UseInfiniteQueryResult<InfiniteData<TData>>

export const getUseInfiniteQuery = (
  fn: FnBase,
  path: string[]
): UseInfiniteQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const [options] = rest
    const key = ["infinite", ...path, ...args]

    const fetchWithPageParam: QueryFunction<
      FnReturn<FnBase>,
      QueryKey,
      any
    > = ({ pageParam }) => {
      const mergedParams = merge([], args, pageParam)
      return fn(...mergedParams)
    }

    const select: UseInfiniteQueryOptions["select"] = options.select
      ? (data) => ({
          ...data,
          pages: data.pages.map(options.select),
        })
      : undefined

    return useInfiniteQuery({
      throwOnError: true,
      ...options,
      select,
      queryKey: key,
      queryFn: fetchWithPageParam,
    })
  }
}
