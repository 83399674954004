import i18next from "i18next"
import { initializeLocalise, loadLokaliseLocales } from "lokalise"
import { initReactI18next } from "react-i18next"
import enUS from "../../../../apps/portal/src/i18n/en-US.json"

const BASE_LOCALE = "en-US"

i18next.use(initReactI18next).init({
  lng: BASE_LOCALE,
  fallbackLng: BASE_LOCALE,
  resources: {
    "en-US": { translation: enUS },
  },
  interpolation: {
    escapeValue: false, // not needed for react
  },
  react: {
    bindI18n: "languageChanged loaded loadedResource",
  },
})

initializeLocalise({
  project: "portal",
})

export const changeLocale = async (locale: string) => {
  loadLocales([locale])

  i18next.changeLanguage(locale)
}

const loadLocales = async (locales: string[]) => {
  const loadedLocales = await loadLokaliseLocales(locales)

  const DEEP = true
  const OVERWRITE = true

  for (const [locale, data] of Object.entries(loadedLocales)) {
    i18next.addResourceBundle(locale, "translation", data, DEEP, OVERWRITE)
  }

  i18next.emit("loadedResource")
}
