import { setContext } from "@sentry/react"
import * as sentry from "sentry"
import { APP_ENV, GIT_COMMIT_SHA, IS_PROD_BUILD } from "../env"
import { APP_TYPE, APP_VERSION, IS_APP } from "../vuplex/constants"

const SENTRY_URL =
  "https://13f650254519e9c2d6bf302bdfdbcdb8@sentry.platform-shared.aws.attensi.com/24"

export const initializeSentry = async () => {
  if (!GIT_COMMIT_SHA) return

  await sentry.initializeSentry({
    isEnabled: IS_PROD_BUILD,
    sentryUrl: SENTRY_URL,
    environment: APP_ENV,
    release: GIT_COMMIT_SHA,
  })

  setContext("App", {
    "Is in app mode?": IS_APP,
    "App type": APP_TYPE,
    "App version": APP_VERSION,
  })
}
