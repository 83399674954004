import type {
  LocaleContentLinkPagination,
  ProductTypeIdentifier,
  ProductUploadPagination,
} from "../graphql"

export const getIsWebGamesProduct = (productType: ProductTypeIdentifier) => {
  const types: ProductTypeIdentifier[] = [
    "fast",
    "assessment",
    "process_on_web",
  ]
  return types.includes(productType)
}

export const getIsLeaderboardProduct = (productType: ProductTypeIdentifier) => {
  const types: ProductTypeIdentifier[] = [
    "behavior",
    "process",
    "skill",
    "operations",
    "workflow",
  ]
  return types.includes(productType)
}

export const getProductLink = (
  productId: number | string,
  productType: ProductTypeIdentifier
) => {
  if (getIsLeaderboardProduct(productType)) {
    return `sim/${productId}`
  }
  if (productType === "activity") {
    return `activity/${productId}`
  }
  if (productType === "meet") {
    return `event/${productId}`
  }
  return `product/${productId}`
}

export const getPreferredUpload = ({
  contentLink,
  locale,
  productUploads,
}: {
  productUploads: Pick<ProductUploadPagination, "nodes">
  contentLink: string
  locale: string | undefined
}) => {
  return (
    productUploads.nodes.find((upload) => upload.locale === locale)?.url ??
    productUploads.nodes[0]?.url ??
    contentLink
  )
}

export const getPreferredContentLink = ({
  locale,
  localeContentLinks,
  contentLink,
}: {
  localeContentLinks: Pick<LocaleContentLinkPagination, "nodes">
  contentLink: string
  locale: string | undefined
}) => {
  return (
    localeContentLinks.nodes.find((upload) => upload.locale === locale)?.link ??
    localeContentLinks.nodes[0]?.link ??
    contentLink
  )
}
