import { bonzai } from "bonzai"
import { runFetch } from "fetcher"
import { assert, number, string, Struct, type } from "superstruct"
import { getConfig } from "../config"
import { apiMock } from "./mock"

type GetSessionDataParams = {
  accessToken: string
}
export const getSessionData = async ({ accessToken }: GetSessionDataParams) => {
  const [user, company] = await Promise.all([
    api.getUser.fetchQuery([{ accessToken }]),
    api.getCompany.fetchQuery([{ accessToken }]),
  ])
  return {
    accessToken: accessToken,
    companyNameKey: company.name_key,
    subdomain: company.attensi_com_subdomain,
    userId: user.id,
    logo: company.logo,
  }
}

const UserResponse = type({
  id: number(),
})

const getUser = async ({ accessToken }: GetSessionDataParams) => {
  const { backofficeUrl, apiToken } = await getConfig()
  return runFetch({
    baseUrl: backofficeUrl,
    path: `/api/v1/users/me`,
    transform: (data) => validate(UserResponse, data),
    headers: {
      "API-Token": apiToken,
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const CompanyResponse = type({
  logo: string(),
  name_key: string(),
  attensi_com_subdomain: string(),
})

const getCompany = async ({ accessToken }: GetSessionDataParams) => {
  const { backofficeUrl, apiToken } = await getConfig()

  return runFetch({
    baseUrl: backofficeUrl,
    path: `/api/v1/companies/current`,
    transform: (data) => validate(CompanyResponse, data),
    headers: {
      "API-Token": apiToken,
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const validate = async <T>(schema: Struct<T>, data: unknown) => {
  assert(data, schema)
  return data
}

// Use bonzai for automatic retries
const api =
  import.meta.env.MODE === "test"
    ? apiMock
    : bonzai({
        getUser,
        getCompany,
      })
