import type { GraphQLEnvironment } from "graphql-mock"
import { BACKOFFICE_API_URL } from "./env"
import { getDevicePlatform } from "./tools/getDevicePlatform"

export const TEXT_FALLBACK = "N/A"
export const DEVICE_PLATFORM = getDevicePlatform()
export const GRAPHQL_ENVIRONMENT: GraphQLEnvironment = {
  baseUrl: BACKOFFICE_API_URL,
  path: "/api/bff/portal/graphql",
}
