import { auth } from "auth"
import { v3 } from "backoffice-api"
import { invalidateAllQueries } from "bonzai"
import i18next from "i18next"
import { PORTAL_LOCALE_KEY } from "./constants"
import { loadLocales } from "./loadLocales"

export const changeLocale = async (locale: string) => {
  loadLocales([locale])

  i18next.changeLanguage(locale)
  auth.updateConfig({ locale })
  document.documentElement.lang = locale

  await updateServerLocale(locale)

  invalidateAllQueries()

  // App launcher breaks if the locale isn't in the manifest
  // changeAppLauncherLocale(locale)
}

const updateServerLocale = async (locale: string) => {
  const userSettings = await v3.getUserSettings()
  const locales = {
    ...userSettings.data.locales,
    [PORTAL_LOCALE_KEY]: locale,
  }
  await v3.updateUserSettings({ locales })
}
