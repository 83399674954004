import s from "./styles.module.scss"

export const IncompleteFilterLoading = () => {
  return (
    <div className={s.loading}>
      <div className={s.loading__header} />
      <div className={s.loading__description} />
    </div>
  )
}
