import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query BundleHeader($id: ID!) {
    productBundle(id: $id) {
      id
      title
      progress {
        certifiedCount
        certifiedMax
        starsCount
        starsMax
      }
    }
  }
`)

export const mockBundleHeaderData = mockData<Data>({
  productBundle: {
    id: "1",
    title: "Product Bundle Title",
    progress: {
      certifiedCount: 50,
      certifiedMax: 100,
      starsCount: 7,
      starsMax: 14,
    },
  },
})

registerMockData(query, mockBundleHeaderData())
