import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type Category = Data["productCategories"]["nodes"][number]

export const query = graphql(`
  query ProductCategories($limit: Int!, $page: Int) {
    productCategories(
      isJourney: false
      isFeatured: false
      limit: $limit
      page: $page
    ) {
      pageInfo {
        nextPage
        nextPageItemCount
      }
      nodes {
        id
        title
      }
    }
  }
`)

export const mockProductCategoriesData = mockData<Data>({
  productCategories: {
    pageInfo: {
      nextPage: null,
      nextPageItemCount: 0,
    },
    nodes: times(10, (index) => ({
      id: index.toString(),
      title: `Category #${1}`,
    })),
  },
})

registerMockData(query, mockProductCategoriesData())
