import { useAsyncData } from "hooks"
import { auth } from "."

//
// It's important not to use React Query with transfer keys for two reasons:
//
// 1. The React Query setup is built around refreshing data often.
//    We cannot refresh a transfer key without getting a new one.
//    When using with an iFrame, it will reload the entire thing and reset the game.
//
// 2. Transfer keys don't last forever and they can only be used once.
//    So it's important not to cache them and generate new ones each time.
//
//    Therefore we use useAsyncData which is a simplified alternative to React Query without caching and refreshing.
//

type Return<T> =
  | {
      link: undefined
      isPending: true
      error: undefined
    }
  | {
      link: T
      isPending: false
      error: undefined
    }
  | {
      link: undefined
      isPending: false
      error: Error
    }

export const useAccessParams = (url: string) => {
  const { data, error, isPending } = useAsyncData(
    () => auth.addAccessParams(url),
    [url]
  )

  return { link: data, error, isPending } as Return<string>
}
