import { Card } from "materia"
import s from "./styles.module.scss"

export const JourneyCardLoading = () => {
  return (
    <Card>
      <div className={s.skeleton}>
        <div className={s.skeleton__bottomType} />
        <div className={s.skeleton__bottomTitle} />
      </div>
    </Card>
  )
}
