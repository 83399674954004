import s from "./styles.module.scss"

export const UserProfileSection = ({
  title,
  children,
}: {
  title?: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <div className={s.section}>
      {title && <h2 className={s.section__title}>{title}</h2>}
      {children}
    </div>
  )
}
