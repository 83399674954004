import bowser from "bowser"
import type { Platform } from "../graphql"

export const getDevicePlatform = (): Platform | undefined => {
  const parser = bowser.getParser(window.navigator.userAgent)
  return pickPlatform(parser.getOS().name)
}

const pickPlatform = (platform: string | undefined): Platform | undefined => {
  // List of possible OS
  // https://github.com/lancedikson/bowser/blob/master/src/constants.js#L93

  switch (platform) {
    case "Windows":
    case "iOS":
    case "Android":
      return platform
    case "macOS":
      return "MacOS"
  }
}
