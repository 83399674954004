import s from "./styles.module.scss"

export const UserInfoHeaderLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__completed} />
      <div className={s.skeleton__stars} />
      <div className={s.skeleton__mandatory} />
    </div>
  )
}
