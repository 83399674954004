import { invalidateAllQueries } from "bonzai"
import { subscribeToChannel } from "../actionCable/subscribeToChannel"
import { useConfettiBannerStore } from "../components/ConfettiBanner/store"

export const initializeActionCable = () => {
  subscribeToChannel("PlaythroughProcessedChannel", async () => {
    const { updateCurrentProductProgress } = useConfettiBannerStore.getState()
    updateCurrentProductProgress()

    invalidateAllQueries()
  })
}
