import { custom } from "../../../bonzai/bonzai"
import { KpiSalesHeaderLoader } from "../KpiSalesHeader/KpiSalesHeaderLoader"
import { UserInfoHeader } from "../UserInfoHeader"
import { UserInfoHeaderLoading } from "../UserInfoHeader/loading"

export const SubHeaders = ({
  showUserInfoHeader = true,
}: {
  showUserInfoHeader: boolean | undefined
}) => {
  // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
  const { data: flags } = custom.getPortalConfigurations.useQuery([])

  if (!flags) return <UserInfoHeaderLoading />

  return (
    <>
      {!flags.disable_completed_courses_stars && showUserInfoHeader && (
        <UserInfoHeader />
      )}
      {flags.enable_kpi_sales_tiles && <KpiSalesHeaderLoader />}
    </>
  )
}
