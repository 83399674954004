import { useNavigate, useSearch } from "@tanstack/react-location"
import { useDisableDocumentScroll } from "./useDisableDocumentScroll"
import { useOnEscape } from "./useOnEscape"
import { useStableCallback } from "./useStableCallback"

export const useModal = (closeRoute: string, onCloseCallback?: () => void) => {
  const navigate = useNavigate()
  const search = useSearch()

  const closeModal = useStableCallback(() => {
    // Used for refreshing the page when the modal is closed
    // Can be deleted when everything is on GraphQL
    onCloseCallback?.()

    navigate({
      replace: true,
      to: closeRoute,
      search: search,
    })
  })

  useOnEscape(closeModal)

  useDisableDocumentScroll() // Not sure about this one. It repositions content and background image

  return { onClose: closeModal }
}
