import { ReactComponent as CheckIcon } from "assets/icons/checkIcon.svg"
import { forwardRef, type InputHTMLAttributes } from "react"
import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
}
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, ...props }, ref) => (
    <label className={modifiers(s, "checkbox", { disabled: props.disabled })}>
      <div className={s.checkbox__box}>
        <input
          type="checkbox"
          className={s.checkbox__input}
          ref={ref}
          {...props}
        />
        <CheckIcon fill="currentColor" className={s.checkbox__checkmark} />
      </div>

      {label && <div className={s.checkbox__label}>{label}</div>}
    </label>
  )
)
