import { times } from "lodash-es"
import { JourneyCardLoading } from "../JourneyCard/loading"
import { JourneyCardBlock } from "./block"
import s from "./styles.module.scss"

export const JourneyProductsLoading = () => {
  return (
    <div>
      <div className={s.startBlock} />
      <div>
        {times(6, (index) => (
          <JourneyCardBlock key={index}>
            <JourneyCardLoading />
          </JourneyCardBlock>
        ))}
      </div>
    </div>
  )
}
