import produce from "immer"
import { merge, mergeWith } from "lodash-es"
import type { InputArg } from "./InputArg"

export const mergeMock = <T>(data: T, input: InputArg<T>) => {
  if (input === undefined) return merge({}, data)
  if (typeof input === "function") return produce(data, input)
  return mergeWith({}, data, input, (_, newValue) => {
    // Don't merge arrays. Replace the entire array with the new value
    if (Array.isArray(newValue)) return newValue
  })
}
