import type { ReactNode } from "react"
import s from "./styles.module.scss"

export const Content = ({
  isOpen,
  children,
}: {
  isOpen: boolean
  children: ReactNode
}) => {
  if (!isOpen) return null
  return <div className={s.content}>{children}</div>
}
