import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query DiplomaModal($productId: ID!) {
    product(id: $productId) {
      id
      title
      productType
      progress {
        certified
      }
      deadlineOccurrence {
        deadlineDate
        status
      }
      diplomaConfiguration {
        title
        description
        showCompanyLogo
        showCompanyName
        showDiplomaDescription
        showIssueDate
        showUserLastName
        showProductTitle
      }
      diplomaProgress {
        certifiedAt
      }
    }
    currentUser {
      name
      firstName
      lastName
    }
    currentCompany {
      name
      logoUrl
    }
  }
`)

export const mockDiplomaModalData = mockData<Data>({
  product: {
    id: "1",
    productType: "link",
    title: "Be Nice 101",
    progress: {
      certified: true,
    },
    diplomaConfiguration: {
      title: "Certified awesome",
      description: "Only the coolest kids get this one",
      showCompanyLogo: true,
      showCompanyName: true,
      showDiplomaDescription: true,
      showIssueDate: true,
      showUserLastName: true,
      showProductTitle: true,
    },
    diplomaProgress: {
      certifiedAt: "2024-03-08T00:00:00+02:00",
    },
    deadlineOccurrence: {
      deadlineDate: "2034-03-08T00:00:00+02:00",
      status: "certified",
    },
  },
  currentUser: {
    name: "Bob",
    firstName: "Bob",
    lastName: "Kowalski",
  },
  currentCompany: {
    name: "Attensi Test",
    logoUrl: "",
  },
})

registerMockData(query, mockDiplomaModalData())
