import {
  type QueryOptions,
  MutationCache,
  QueryCache,
} from "@tanstack/react-query"
import { auth } from "auth"
import { QueryClient } from "bonzai"
import { FetchError, isFetchError } from "fetcher"

const onRetry: QueryOptions["retry"] = (failureCount, error) => {
  const possibleNetworkError = isFetchError(error) && error.didRequestFail
  if (possibleNetworkError) return failureCount < 3

  return false
}

const handleFetchErrors = async (error: Error) => {
  if (error instanceof FetchError) {
    if (error.status === 401) {
      await auth.refreshLogin()
    }
  }
}

export const createQueryClient = () => {
  return new QueryClient({
    queryCache: new QueryCache({
      onError: handleFetchErrors,
    }),
    mutationCache: new MutationCache({
      onError: handleFetchErrors,
    }),
    defaultOptions: {
      queries: {
        retry: onRetry,
      },
    },
  })
}
