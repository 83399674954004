import { JourneyHeaderLoading } from "../JourneyHeader/loading"
import { JourneyProductsLoading } from "../JourneyProducts/loading"
import { Content } from "./content"
import s from "./styles.module.scss"

export const JourneyLoading = ({ isOpen = true }: { isOpen?: boolean }) => {
  return (
    <div className={s.skeleton}>
      <JourneyHeaderLoading />

      <Content isOpen={isOpen}>
        <JourneyProductsLoading />
      </Content>
    </div>
  )
}
