import chevronIcon from "assets/icons/chevronIcon.svg"
import { useTranslation } from "react-i18next"
import { useNavigateBack } from "../../../tools/useNavigateBack"
import s from "./styles.module.scss"

export const EventHeader = () => {
  const { t } = useTranslation()
  const navigateBack = useNavigateBack()

  return (
    <div className={s.header}>
      <div className={s.header__centered}>
        <div className={s.header__widgetLayoutSize}>
          <button className={s.header__back} onClick={() => navigateBack()}>
            <img className={s.header__backIcon} src={chevronIcon} alt="" />
          </button>
          <div className={s.header__title}>{t("event.ALL_EVENTS")}</div>
        </div>
      </div>
    </div>
  )
}
