import confetti from "canvas-confetti"
import { waitMillis } from "utils"

export const triggerConfetti = async () => {
  const timingPause = 400

  await waitMillis(timingPause)

  explodeInRandomLocation()

  await waitMillis(timingPause)

  explodeInRandomLocation()

  await waitMillis(timingPause / 2)

  explodeInRandomLocation()

  await waitMillis(timingPause)

  explodeInRandomLocation()
}

const explodeInRandomLocation = () => {
  explode({ origin: getRandomCoordinates() })
}

const explode = async (options: confetti.Options = {}) => {
  // https://github.com/catdad/canvas-confetti

  return confetti({
    spread: 360,
    angle: 270,
    decay: 0.96,
    startVelocity: 45,
    particleCount: 100,
    scalar: 1.4,
    disableForReducedMotion: true,
    ...options,
  })
}

const getRandomCoordinates = () => ({
  x: Math.random(),
  y: Math.random(),
})
