import diplomaMedal from "assets/illustrations/diplomaMedal.svg"
import diplomaRibbon from "assets/illustrations/diplomaRibbon.svg"
import { formatDate } from "format"
import { upperFirst } from "lodash-es"
import { useTranslation } from "react-i18next"
import type { DiplomaPreviewProps } from "../types"

import s from "./styles.module.scss"

export const DiplomaPreviewUnlocked = (props: DiplomaPreviewProps) => {
  return (
    <div className={s.diploma}>
      <div className={s.diploma__section}>
        <img className={s.medal} src={diplomaMedal} alt="Diploma badge" />

        <div className={s.ribbon}>
          <div className={s.ribbon__text} id="ribbon__text">
            {props.title}
          </div>
          <img className={s.ribbon__img} src={diplomaRibbon} alt="" />
        </div>

        <Owner {...props} />
      </div>

      <div className={s.diploma__section}>
        <Company {...props} />
        <ProductTitle {...props} />
        <Description {...props} />

        <Dates {...props} />
      </div>
    </div>
  )
}

const Owner = ({
  firstName,
  lastName,
  showUserLastName,
}: DiplomaPreviewProps) => {
  const { t } = useTranslation()

  return (
    <div className={s.owner}>
      <div className={s.owner__desc}>{t("diploma.CERTIFICATE_AWARDED_TO")}</div>
      <div className={s.owner__name}>
        {firstName} {showUserLastName && lastName}
      </div>
    </div>
  )
}

const Company = ({
  companyName,
  companyLogo,
  showCompanyName,
  showCompanyLogo,
}: DiplomaPreviewProps) => {
  const { t } = useTranslation()
  const showCompanyText = showCompanyLogo || showCompanyName

  // Forces redownloading image and disregard cache.
  // If not done, company logo won't print on diplomas in Chromium
  // based browsers due to https://issues.chromium.org/issues/41025985
  const src = `${companyLogo}?c=${Date.now()}`

  return (
    <div className={s.company}>
      {showCompanyText && (
        <div className={s.company__text}>{t("diploma.FROM")}</div>
      )}
      {showCompanyLogo && (
        <img
          className={s.company__logo}
          src={src}
          alt="Company logo"
          crossOrigin="anonymous"
        />
      )}
      {showCompanyName && <div className={s.company__text}>{companyName}</div>}
    </div>
  )
}

const ProductTitle = ({
  productTitle,
  showProductTitle,
}: DiplomaPreviewProps) => {
  const { t } = useTranslation()

  if (showProductTitle)
    return (
      <div className={s.product}>
        <div className={s.product__text}>{t("diploma.FOR_COMPLETING")}</div>
        <div className={s.product__title}>{productTitle}</div>
      </div>
    )
}

const Description = ({
  description,
  showDiplomaDescription,
}: DiplomaPreviewProps) => {
  if (description && showDiplomaDescription)
    return <div className={s.description}>{description}</div>
}

const Dates = ({
  issueDate,
  expirationDate,
  showIssueDate,
}: DiplomaPreviewProps) => {
  const { i18n, t } = useTranslation()

  return (
    <div className={s.date}>
      {showIssueDate && issueDate && (
        <div className={s.date__issued}>
          {t("diploma.ISSUED")} {formatDeadlineDate(issueDate, i18n.language)}
        </div>
      )}
      {expirationDate && (
        <div className={s.date__expires}>
          {t("diploma.EXPIRES")}{" "}
          {formatDeadlineDate(expirationDate, i18n.language)}
        </div>
      )}
    </div>
  )
}

const formatDeadlineDate = (deadlineDate: string, locale: string) => {
  const formatted = formatDate(deadlineDate, locale, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
  return upperFirst(formatted)
}
