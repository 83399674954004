import type { ReactNode } from "react"
import { modifiers } from "ui-tools"
import type { MateriaColor } from "../../types/MateriaColor"
import { getCssColor } from "../../utils/getCssColor"
import s from "./ProgressCircle.module.scss"

export const ProgressCircle = ({
  value,
  stroke = 5,
  maxValue = 100,
  variant,
  backgroundColor = "pebble",
  fillBackground = false,
  children,
}: {
  value: number
  variant: "green" | "purple" | "companyTheme" | "white" | "coal"
  stroke?: number
  maxValue?: number
  backgroundColor?: MateriaColor
  fillBackground?: boolean
  children?: ReactNode
}) => {
  const center = 50
  const radius = center - stroke / 2

  return (
    <div className={s.progress}>
      <svg
        className={modifiers(s, "progress__svg", variant)}
        viewBox="0 0 100 100"
      >
        <circle
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={stroke}
          stroke={getCssColor(backgroundColor)}
          fill={fillBackground ? getCssColor(backgroundColor) : "none"}
        />
        <circle
          className={s.progress__circle}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={stroke}
          fill="none"
          strokeLinecap="round"
          pathLength="1"
          stroke="currentColor"
          strokeDasharray={`${value / maxValue}px 1px`}
        />
      </svg>
      <div className={s.progress__content}>{children}</div>
    </div>
  )
}
