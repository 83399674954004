import { upperFirst } from "lodash-es"

export const getLocaleDisplayName = (locale: string) => {
  const hardcodedDisplayName = getHardcodedDisplayName(locale)
  if (hardcodedDisplayName) return hardcodedDisplayName

  const displayNamesIsNotSupported = Intl.DisplayNames === undefined
  if (displayNamesIsNotSupported) return locale

  const displayNames = new Intl.DisplayNames(locale, {
    type: "language",
    languageDisplay: "standard",
  })
  const languageName = displayNames.of(locale)
  return upperFirst(languageName)
}

const getHardcodedDisplayName = (locale: string) => {
  switch (locale) {
    case "my-MM":
      return "မြန်မာဘာသာ (ဗမာ)"
    case "cnh":
      return "Hakha Chin (Chin)"
  }
}
