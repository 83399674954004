import {
  graphqlRequest as _graphqlRequest,
  type TypedDocumentNode,
} from "graphql-mock"
import { GRAPHQL_ENVIRONMENT } from "../constants"

type Options<TData, TVariables extends Record<string, any>> = {
  query: TypedDocumentNode<TData, TVariables>
  variables: TVariables
}

export const graphqlRequest = async <
  TData,
  TVariables extends Record<string, any>
>({
  query,
  variables,
}: Options<TData, TVariables>): Promise<TData> => {
  return _graphqlRequest({
    environment: GRAPHQL_ENVIRONMENT,
    query,
    variables,
  })
}
