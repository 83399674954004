import { type V3 } from "backoffice-api"
import { formatDate } from "format"
import type { TFunction } from "i18next"
import { upperFirst } from "lodash-es"
import { getTimeDifference } from "./getTimeDifference"

type EventDate = V3["getProductCourse"]["data"]

export const formatEventDate = (eventDate: EventDate, locale: string) => {
  const month = formatDate(eventDate.start_datetime, locale, { month: "short" })
  const day = formatDate(eventDate.start_datetime, locale, { day: "2-digit" })
  const time = formatDate(eventDate.start_datetime, locale, {
    hour: "numeric",
    minute: "numeric",
  })

  return { month, day, time }
}

export const formatEventFullDate = (eventDate: EventDate, locale: string) => {
  const formatted = formatDate(eventDate.start_datetime, locale, {
    weekday: "long",
    month: "short",
    day: "numeric",
  })
  return upperFirst(formatted)
}

export const formatEventTime = (eventDate: EventDate, locale: string) => {
  const startDate = formatDate(eventDate.start_datetime, locale, {
    hour: "numeric",
    minute: "numeric",
  })

  const endDate = formatDate(eventDate.end_datetime, locale, {
    hour: "numeric",
    minute: "numeric",
  })

  return `${startDate} - ${endDate}`
}

export const formatEventDayMonth = (eventDate: EventDate, locale: string) => {
  const startDateTime = new Date(eventDate.start_datetime)
  const endDateTime = new Date(eventDate.end_datetime)

  const startDate = formatDate(startDateTime, locale, {
    day: "numeric",
    month: "short",
  })
  const endDate = formatDate(endDateTime, locale, {
    day: "numeric",
    month: "short",
  })

  if (startDateTime.getDate() !== endDateTime.getDate())
    return `${startDate} - ${endDate}`
  return startDate
}

export const formatEventDay = (eventDate: EventDate) => {
  const startDay = new Date(eventDate.start_datetime).getDate()
  const endDay = new Date(eventDate.end_datetime).getDate()

  if (startDay !== endDay) return `${startDay}-${endDay}`
  return startDay.toString()
}

export const formatEventLength = (eventDate: EventDate, t: TFunction) => {
  const startDate = new Date(eventDate.start_datetime)
  const endDate = new Date(eventDate.end_datetime)
  const { days, hours, minutes } = getTimeDifference(startDate, endDate)

  if (days > 0) return `${days + 1}${t("date.DAY_SHORT")}`

  if (hours > 0) {
    const remainingMinutes = minutes - hours * 60

    return minutes > 0
      ? `${hours}${t("date.HOUR_SHORT")} ${remainingMinutes}${t(
          "date.MINUTE_SHORT"
        )}`
      : `${hours}${t("date.HOUR_SHORT")}`
  }

  return `${minutes}${t("date.MINUTE_SHORT")}`
}

export const pickEventDate = (dates: EventDate[]) => {
  const validDates = dates.filter((date) => !date.cancelled)
  const firstAttendingDate = validDates.find(
    (date) => date.is_attending || date.is_on_waiting_list
  )
  const firstDate = validDates[0]
  return firstAttendingDate ?? firstDate
}

export const getHasEventExpired = (event: EventDate) => {
  const eventDate = new Date(event.end_datetime).getTime()
  return eventDate <= Date.now()
}

export const getIsEventFull = (event: EventDate | undefined) => {
  if (event === undefined) return false

  return event.attendees_count >= event.attendees_count_max
}

export const getIsEventPastDeadline = (deadline: string | null) => {
  if (deadline === null) return false

  const deadlineTimestamp = new Date(deadline).getTime()

  return deadlineTimestamp <= Date.now()
}
