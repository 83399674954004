import {
  useMutation,
  type MutationFunction,
  type UseMutationResult,
} from "@tanstack/react-query"
import { invalidateAllQueries } from "bonzai"
import { useContext } from "react"
import { never } from "utils"
import { type TypedDocumentNode } from "."
import { EnvironmentContext } from "./EnvironmentContext"
import { ForceStateContext } from "./context"
import { graphqlRequest } from "./graphqlRequest"

export const useGraphqlMutation = <
  TData,
  TVariables extends Record<string, any>
>(
  query: TypedDocumentNode<TData, TVariables>
): UseMutationResult<TData, Error, TVariables> => {
  const forceState = useContext(ForceStateContext)
  const environment = useContext(EnvironmentContext)
  if (!environment) throw new Error("Missing environment context")

  const mutationFn: MutationFunction<TData, TVariables> = async (variables) => {
    if (forceState !== "live") return never()

    const result = await graphqlRequest({ query, variables, environment })

    invalidateAllQueries()

    return result
  }

  const queryResult = useMutation<TData, Error, TVariables>({
    mutationFn,
  })

  return queryResult
}
