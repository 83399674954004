import { saveAs } from "file-saver"
import html2canvas, { type Options } from "html2canvas"
import { jsPDF, type jsPDFOptions } from "jspdf"
import { useRef, useState } from "react"

const optionsHTML2Canvas: Partial<Options> = {
  /**
   * Allows using cross-origin images.
   * In case of diplomas, allows printing company logo.
   */
  allowTaint: true,
  /**
   * Allows using cross-origin images.
   * In case of diplomas, allows printing company logo.
   */
  useCORS: true,
  /**
   * Scales the resulting canvas so the printed PDF is of higher quality.
   * Remember to keep in mind the maximum canvas sizes for various browsers:
   * https://jhildenbiddle.github.io/canvas-size/
   * If scaled canvas exceeds browser's limit, it won't render.
   */
  scale: 5,
  /**
   * Allows ignoring problematic elements.
   * Example: html2canvas hangs if the targeted document has any lazy
   * loaded images, even if they aren't a part of the printable element.
   * https://github.com/niklasvh/html2canvas/issues/3053
   */
  ignoreElements: (element) => {
    return element.getAttribute("loading") === "lazy"
  },
}

const optionsJSPDF: jsPDFOptions = { orientation: "portrait", format: "a4" }

/**
 * Allows to download the content of a specified HTML element as a PDF file.
 * Element that has the printRef provided will first be converted to a canvas
 * (options are specified in optionsHTML2Canvas above), which then gets converted
 * to PDF (options are specified in optionsJSPDF above).
 * @param fileName Optional name for the downloaded file
 * @param optionsCanvas Optional options for HTML2Canvas. It is especially useful
 * to define `onclone` method which allows us to modify the HTML document before
 * it gets converted to a canvas. It can be used to fix a HTML2Canvas issue where
 * some elements get shifted relative to their original positions:
 * https://github.com/niklasvh/html2canvas/issues/2042
 * @param optionsPDF Optional options for JSPDF
 */
export const useDownloadHTMLAsPDF = (
  fileName?: string,
  optionsCanvas: Partial<Options> = {},
  optionsPDF: jsPDFOptions = {}
) => {
  const [isDownloadPending, setIsDownloadPending] = useState(false)
  const printRef = useRef<HTMLDivElement>(null)

  const onDownload = async () => {
    setIsDownloadPending(true)

    const name = (fileName ?? `attensi-${new Date().toISOString()}`) + ".pdf"
    const pdf = await createPDF()
    downloadPDF(pdf, name)

    setIsDownloadPending(false)
  }

  const createPDF = async () => {
    const printableElement = printRef.current

    if (!printableElement) {
      throw new Error("Printable element is not found")
    }

    const canvas = await html2canvas(printableElement, {
      ...{ ...optionsHTML2Canvas, ...optionsCanvas },
      ...{ ...optionsJSPDF, ...optionsPDF },
    })
    const data = canvas.toDataURL("image/png")

    const pdf = new jsPDF(optionsJSPDF)
    const { width, height } = pdf.getImageProperties(data)
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const pdfWidth = (pdfHeight / height) * width
    // Center the image on the page
    const offsetX = pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2
    const offsetY = pdf.internal.pageSize.getHeight() / 2 - pdfHeight / 2

    pdf.addImage(data, "png", offsetX, offsetY, pdfWidth, pdfHeight)

    return pdf
  }

  // We don't use jsPDF's built-in save method because it defaults to Blob,
  // which has some issues on iOS/iPad OS. Instead we use file-saver
  // (which is the same library as one used in jsPDF) so we can configure
  // output to be data URL instead, which works for all browsers.
  const downloadPDF = (pdf: jsPDF, fileName: string) => {
    const dataUrl = pdf.output("datauristring")
    saveAs(dataUrl, fileName)
  }

  return { printRef, isDownloadPending, onDownload }
}
