import { useCallback, useEffect, useRef, useState } from "react"

const initialValue = Symbol()

type Return<T> =
  | {
      data: undefined
      isPending: true
      error: undefined
    }
  | {
      data: T
      isPending: false
      error: undefined
    }
  | {
      data: undefined
      isPending: false
      error: Error
    }

/*
  Useful when React Query is not appropriate for example when caching or refetching isn't wanted.
*/
export const useAsyncData = <T>(
  fetchData: () => Promise<T>,
  dependencies: unknown[]
): Return<T> => {
  const dataRef = useRef<T | typeof initialValue>(initialValue)
  const errorRef = useRef<Error>()
  const fetchCounter = useRef(0)
  const [isPending, setIsPending] = useState(true)

  // This is on purpose since we want to memoize the fetchData function, and not get a new function every time fetchData changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetchData = useCallback(fetchData, dependencies)

  useEffect(() => {
    setIsPending(true)

    const currentFetchCounter = fetchCounter.current
    const getIsCurrentFetch = () => currentFetchCounter === fetchCounter.current

    memoizedFetchData()
      .then((data) => {
        if (getIsCurrentFetch()) dataRef.current = data
      })
      .catch((error) => {
        if (getIsCurrentFetch()) errorRef.current = error
      })
      .finally(() => {
        if (getIsCurrentFetch()) setIsPending(false)
      })

    return () => {
      dataRef.current = initialValue
      errorRef.current = undefined
      fetchCounter.current += 1
    }
  }, [memoizedFetchData])

  if (isPending) {
    return { isPending: true, data: undefined, error: undefined }
  }

  if (errorRef.current !== undefined) {
    return { isPending: false, data: undefined, error: errorRef.current }
  }

  if (dataRef.current === initialValue) {
    throw new Error("Fetched data should exist at this point")
  }

  return { isPending: false, data: dataRef.current, error: undefined }
}
