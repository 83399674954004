import { times } from "lodash-es"
import s from "./styles.module.scss"

export const ExploreFiltersLoading = () => {
  return (
    <div className={s.filters}>
      {times(5, (index) => (
        <FilterLoading key={index} />
      ))}
    </div>
  )
}

const FilterLoading = () => {
  return <button className={s.filterSkeleton}>&nbsp;</button>
}
