import { useEffect } from "react"
import { changeLocale } from "../../i18n"
import { DiplomaPreviewLocked } from "./DiplomaPreviewLocked"
import { DiplomaPreviewUnlocked } from "./DiplomaPreviewUnlocked"
import type { DiplomaPreviewProps } from "./types"

import s from "./DiplomaPreview.module.scss"

export const DiplomaPreview = (props: DiplomaPreviewProps) => {
  const { contentLocale } = props

  useEffect(() => {
    changeLocale(contentLocale)
  }, [contentLocale])

  return (
    <div className={s.diplomaPreview}>
      {props.isUnlocked ? (
        <DiplomaPreviewUnlocked {...props} />
      ) : (
        <DiplomaPreviewLocked {...props} />
      )}
    </div>
  )
}
