import type { TypedDocumentNode } from "@graphql-typed-document-node/core"
import { auth } from "auth"
import { runFetch } from "fetcher"
import { print } from "graphql"
import { getQueryParams, waitMillis } from "utils"
import type { GraphQLEnvironment } from "./EnvironmentContext"

const { delay } = getQueryParams()

type Options<TData, TVariables extends Record<string, any>> = {
  query: TypedDocumentNode<TData, TVariables>
  variables: TVariables
  environment: GraphQLEnvironment
  signal?: AbortSignal
}

export const graphqlRequest = async <
  TData,
  TVariables extends Record<string, any>
>({
  environment,
  query,
  variables,
  signal,
}: Options<TData, TVariables>): Promise<TData> => {
  if (delay) {
    await waitMillis(Number(delay))
  }

  return runFetch({
    method: "POST",
    baseUrl: environment.baseUrl,
    path: environment.path,
    data: { query: print(query), variables },
    headers: await getHeaders(),
    params: { name: getOperationName(query) },
    transform: (response) => parseResponse(response),
    signal,
  })
}

const parseResponse = <TData>(response: unknown): TData => {
  const { data, errors } = response as {
    data?: TData
    errors?: { message: string }[]
  }
  if (errors?.[0]) {
    throw new Error(errors[0].message)
  }
  if (!data) {
    throw new Error("No data returned")
  }
  return data
}

const getHeaders = async () => {
  const { accessToken } = await auth.getActiveSession()
  return {
    Authorization: "Bearer " + accessToken,
  }
}

const getOperationName = <TData, TVariables extends Record<string, any>>(
  query: TypedDocumentNode<TData, TVariables>
) => {
  const definition = query.definitions.find((definition) => {
    return definition.kind === "OperationDefinition"
  })

  if (definition && "name" in definition) {
    return definition.name?.value
  }
}
