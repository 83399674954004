import { useRef, useState } from "react"

export const useOptimisticUpdate = <
  T extends boolean | string | number | null
>({
  liveValue,
  updateFn,
}: {
  liveValue: T
  updateFn: (newValue: T) => void
}) => {
  const [optimisticValue, setOptimisticValue] = useState<T>()
  const valueAtLastOptimisticUpdate = useRef<T>()

  const getResolvedValue = () => {
    if (optimisticValue === undefined) return liveValue
    if (valueAtLastOptimisticUpdate.current !== liveValue) return liveValue
    return optimisticValue
  }

  const optimisticUpdate = (newValue: T) => {
    valueAtLastOptimisticUpdate.current = liveValue

    setOptimisticValue(newValue)
    updateFn(newValue)
  }

  const isUpdated = optimisticValue !== undefined

  return [getResolvedValue(), optimisticUpdate, isUpdated] as const
}
