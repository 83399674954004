import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import enUS from "../i18n/en-US.json"

export const initializeI18nNext = () => {
  const BASE_LOCALE = "en-US"

  i18next.use(initReactI18next).init({
    lng: BASE_LOCALE,
    fallbackLng: BASE_LOCALE,
    resources: {
      "en-US": { translation: enUS },
    },
    interpolation: {
      escapeValue: false, // not needed for react
    },
    react: {
      bindI18n: "languageChanged loaded loadedResource",
    },
  })
}
