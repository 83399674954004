import type { ProductTypeIdentifier } from "../graphql"

export const isHandledByVuplex = (productType: ProductTypeIdentifier) => {
  const typesHandledByVuplex: ProductTypeIdentifier[] = [
    "act",
    "app",
    "behavior",
    "file",
    "link",
    "operations",
    "pdf",
    "process",
    "skill",
    "workflow",
    "xapi",
  ]
  return typesHandledByVuplex.includes(productType)
}
