import {
  array,
  assign,
  boolean,
  enums,
  lazy,
  literal,
  nullable,
  number,
  optional,
  record,
  string,
  Struct,
  type,
  unknown,
} from "superstruct"
import { ProductType } from "../v1/v1Responses"

export const Links = type({
  first: string(),
  last: string(),
  next: nullable(string()),
  prev: nullable(string()),
  self: string(),
})

export const Pagination = type({
  current_page: number(),
  limit_value: number(),
  total_count: number(),
  total_pages: number(),
})

export const Phone = type({
  country_code: nullable(string()),
  phone_digits: nullable(string()),
  phone_number: string(),
})

export const Locale = type({
  code: string(),
  name: string(),
})

export const TranslatedText = record(string(), nullable(string()))
export const TranslatedFile = type({
  id: number(),
  locale: string(),
  url: string(),
})

export const AccessibleResource = type({
  name: string(),
  resource_type: string(),
})

export const Accessible = type({
  accessible_resource: AccessibleResource,
  action: string(),
})

export const User = type({
  first_name: string(),
  last_name: string(),
  email: nullable(string()),
  username: nullable(string()),
  external_user_id: nullable(string()),
  avatar_id: nullable(number()),
  phone: Phone,
  anonymous: boolean(),
  accessibles: array(Accessible),
  roles: array(string()),
  active: boolean(),
  report: boolean(),
})

export const UserResponse = type({
  data: User,
})

export const Company = type({
  id: string(),
  background_url: string(),
  logo_url: string(),
  name: string(),
  name_key: string(),
  subdomain: string(),
  self_registration: boolean(),
})

export const CompanyResponse = type({
  data: Company,
})

export const LandingPage = enums([
  "for_you",
  "journey",
  "leaderboards",
  "explore",
  "news",
] as const)

export const CompanySettings = type({
  id: string(),
  default_locale: Locale,
  default_portal_landing_page: LandingPage,
  selected_feature_flags: array(string()),
  onboarding_steps: array(string()),
  portal_background_url: nullable(string()),
  portal_configurations: array(unknown()),
  portal_supported_locales: array(Locale),
  supported_configurations: array(string()),
  supported_locales: array(Locale),
  user_display_name_format: enums([
    "username",
    "firstname",
    "firstname_and_lastname",
  ] as const),
})

export const CompanySettingsResponse = type({
  data: CompanySettings,
})

export const NewsPostReaction = type({
  id: string(),
  user_id: number(),
  reaction_type: enums(["like", "view"] as const),
})

export const NewsPost = type({
  id: string(),
  body: TranslatedText,
  updated_at: string(),
  headline: TranslatedText,
  is_liked: boolean(),
  is_viewed: boolean(),
  likes_count: number(),
  view_count: number(),
  image: optional(
    type({
      file_url: string(),
    })
  ),
  reactions: optional(array(NewsPostReaction)),
})

export const NewsPostsResponse = type({
  data: array(NewsPost),
})

export const NewsPostResponse = type({
  data: NewsPost,
})

export const CreateNewsPostReactionResponse = type({
  data: NewsPostReaction,
})

export const ActivityItem = type({
  done: boolean(),
  id: string(),
  title: TranslatedText,
})

export const ActivityItemsResponse = type({
  data: array(ActivityItem),
})

export const ActivityProgressResponse = type({
  data: type({
    progress_count: number(),
    progress_of: number(),
  }),
})

export const UserProductResponse = type({
  data: type({
    mandatory: boolean(),
    stars_requirement: number(),
  }),
})

export const UpdateActivityItemResponse = type({
  data: type({
    activity_item_id: number(),
    done: boolean(),
    user_id: number(),
  }),
})

export const OathApplication = type({
  id: string(),
  api_token: string(),
  platform: string(),
})

export const LocaleContentLink = type({
  id: string(),
  link: string(),
  locale: string(),
})

export const ProductCourse = type({
  id: string(),
  attendees_count: number(),
  attendees_count_max: number(),
  waiting_list_count: number(),
  waiting_list_position: number(),
  is_attending: boolean(),
  is_on_waiting_list: boolean(),
  cancelled: boolean(),
  created_at: string(),
  end_datetime: string(),
  instructor: string(),
  location: string(),
  room: nullable(string()),
  start_datetime: string(),
  signup_deadline: nullable(string()),
  updated_at: string(),
  course_material: nullable(string()),
  meeting_link: nullable(string()),
  waiting_list_enabled: boolean(),
})

export const Product = type({
  apple_store_link: string(),
  certification_requirement: number(),
  company_id: number(),
  content_link: string(),
  description_long: nullable(TranslatedText),
  description_short: nullable(TranslatedText),
  file_uploads: array(TranslatedFile),
  google_play_link: string(),
  google_play_test_link: nullable(string()),
  has_vpp_codes: boolean(),
  id: string(),
  launcher_link: string(),
  locale_content_links: optional(array(LocaleContentLink)),
  manager_approval: boolean(),
  no_score: boolean(),
  picker_app: boolean(),
  product_image_url: string(),
  product_type: type({
    description: TranslatedText,
    identifier: ProductType,
    open_with: enums(["launcher", "href"] as const),
  }),
  open_external_browser: boolean(),
  show_navigation_bar: boolean(),
  self_serve: boolean(),
  supported_platforms: array(string()),
  test_flight_link: nullable(string()),
  title: TranslatedText,
  token: nullable(string()),
  use_transfer_token: boolean(),
  webgl_content_link: string(),
  webgl_responsive: boolean(),
  oauth_applications: optional(array(OathApplication)),
  courses: optional(array(ProductCourse)),
  company: optional(Company),
  created_at: string(),
  updated_at: string(),
  scorm_strict_scoring: boolean(),
})

export const ContentProduct = type({
  id: string(),
  title: TranslatedText,
  no_score: boolean(),
  image_url: string(),
  type: type({ identifier: ProductType }),
})

export const ProductBundle = type({
  id: string(),
  description: nullable(string()),
  images: array(string()),
  title: TranslatedText,
})

export const ProductResponse = type({
  data: Product,
})

export const ProductProgress = type({
  certified_count: number(),
  certified_max: number(),
  certified_req: number(),
  certified: boolean(),
  high_score: number(),
  mandatory_count: number(),
  mandatory_of: number(),
  percentage: number(),
  play_count: number(),
  stars_count: number(),
  stars_max: number(),
})

export const ProductProgressResponse = type({
  data: ProductProgress,
})

export const ProductDeadlineResponse = type({
  data: nullable(
    type({
      deadline_date: nullable(string()),
      status: enums([
        "certified_after_deadline",
        "certified",
        "overdue",
        "due",
      ] as const),
    })
  ),
})

type GroupType = {
  id: string
  name: string
  children?: GroupType[]
}
export const Group: Struct<GroupType> = lazy(() =>
  type({
    id: string(),
    name: string(),
    children: optional(array(Group)),
  })
)

export const ProductCourseResponse = type({
  data: assign(
    ProductCourse,
    type({
      group: optional(Group),
    })
  ),
})

export const GroupResponse = type({
  data: Group,
})

export const GroupUsersResponse = type({
  data: array(
    type({
      avatar_id: nullable(number()),
      avatar_url: string(),
      company_id: number(),
      country_code: nullable(string()),
      created_at: string(),
      department: nullable(string()),
      email: string(),
      first_name: string(),
      id: string(),
      last_name: string(),
      phone_number: nullable(string()),
      position: nullable(string()),
      type: literal("users"),
      updated_at: string(),
      username: nullable(string()),
    })
  ),
  links: Links,
})

export const ProductCategoryProgressResponse = type({
  data: type({
    certified_count: number(),
    certified_max: number(),
    locked: boolean(),
    stars_count: number(),
    stars_max: number(),
    product_categories: optional(
      array(
        type({
          id: string(),
          title: TranslatedText,
        })
      )
    ),
    product_categories_unlocked: optional(
      array(
        type({
          id: string(),
          title: TranslatedText,
          background_image_url: nullable(string()),
        })
      )
    ),
  }),
})

export const KpiSalesData = type({
  year_count: number(),
  update_at: string(),
  current_month_count: type({
    timestamp: string(),
    value: number(),
  }),
  months: array(
    type({
      timestamp: string(),
      value: number(),
    })
  ),
})

export const KpiSalesResponse = type({
  data: nullable(KpiSalesData),
})

export const ProductsResponse = type({
  data: array(Product),
  links: Links,
})

export const Department = type({
  id: string(),
  name: string(),
  company_id: number(),
  parent_id: nullable(number()),
  created_at: string(),
  updated_at: string(),
})

export const DepartmentsResponse = type({
  data: array(Department),
  links: Links,
})

export const CategoryContentResponse = type({
  data: array(
    type({
      product: optional(ContentProduct),
      product_bundle: optional(ProductBundle),
      product_id: nullable(number()),
      product_bundle_id: nullable(number()),
      position: number(),
    })
  ),
  // links: Links,
  meta: type({ pagination: Pagination }),
})

export const BundleResponse = type({
  data: type({
    id: string(),
    title: TranslatedText,
  }),
})

export const BundleStatusResponse = type({
  data: type({
    id: string(),
    stars_count: number(),
    stars_max: number(),
    certified_count: number(),
    certified_max: number(),
  }),
})

export const BundleProductsResponse = type({
  data: array(Product),
  links: Links,
})

export const UserSettingsResponse = type({
  data: type({
    locales: record(string(), string()),
  }),
})
