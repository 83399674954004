import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query FrontView {
    currentCompany {
      portalLandingPage
    }
  }
`)

export const mockFrontViewData = mockData<Data>({
  currentCompany: {
    portalLandingPage: "for_you",
  },
})

registerMockData(query, mockFrontViewData())
