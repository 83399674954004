import { Card } from "materia"
import s from "./styles.module.scss"

export const UserWidgetLoading = () => {
  return (
    <Card borderRadius="24">
      <div className={s.skeleton}>
        <div className={s.skeleton__user}>
          <div className={s.skeleton__userAvatar} />
          <div className={s.skeleton__userInfo}>
            <div className={s.skeleton__userInfoName} />
            <div className={s.skeleton__userInfoPosition} />
          </div>
        </div>

        <div className={s.skeleton__progress} />
        <div className={s.skeleton__filters}>
          {/* <div className={s.skeleton__filter} /> */}
          <div className={s.skeleton__filter} />
        </div>
      </div>
    </Card>
  )
}
