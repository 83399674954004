import * as Sentry from "@sentry/react"
import { showErrorPopup } from "error-popup"
import { useEffect } from "react"

type ErrorPopupProps = {
  error: unknown | unknown[]
}
export const ErrorPopup = ({ error }: ErrorPopupProps) => {
  const errorArray = Array.isArray(error) ? error : [error]

  useEffect(() => {
    for (const error of errorArray) {
      showErrorPopup({ error })
      Sentry.captureException(error)
    }
  }, [...errorArray])

  return null
}
