import { v3 } from "backoffice-api"

type Flag =
  | "disable_completed_courses_stars"
  | "disable_event_widget_page"
  | "disable_global_leaderboard"
  | "disable_leaderboard"
  | "disable_top_menu"
  | "disable_user_profile"
  | "disable_username_editing"
  | "enable_activity_feed"
  | "enable_beta_portal"
  | "enable_kpi_sales_tiles"
  | "enable_learning_journey"
  | "enable_phone_number_editing"
  | "enable_portal_background"
  | "enable_portal_news"
  | "portal_onboarding"

type PortalConfigurations = Record<Flag, boolean>

/**
 * Makes it easier to work with the portal configuration flags by creating an object with a boolean property for each flag.
 *
 * Example:
 * {
 *   disable_leaderboard: true,
 *   enable_portal_news: false,
 * }
 */
export const getPortalConfigurations = async () => {
  const { data: settings } = await v3.getCurrentCompanySettings.fetchQueryOnce(
    []
  )

  const flags = Object.fromEntries(
    settings.portal_configurations.map((flag) => [flag, true])
  )

  // Use proxy to fallback to false for undefined flags.
  return new Proxy(flags, {
    get: getPortalConfiguration,
  }) as PortalConfigurations
}

const getPortalConfiguration = (
  configurations: Partial<PortalConfigurations>,
  flag: keyof PortalConfigurations
) => {
  return configurations[flag] ?? false
}
