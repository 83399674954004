import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["product"]

export const query = graphql(`
  query ConfettiBanner($id: ID!) {
    product(id: $id) {
      title
    }
  }
`)

export const mockConfettiBannerData = mockData<Data>({
  product: {
    title: "The sales team",
  },
})

registerMockData(query, mockConfettiBannerData())
