import { useLayoutEffect, type ReactNode } from "react"
import { createPortal } from "react-dom"
import { useDialogContainerContext } from "./context"
import s from "./styles.module.scss"
export * from "./container"

export const Dialog = ({
  children,
  onClose,
  ariaLabel,
  className,
}: {
  children: ReactNode
  ariaLabel: string
  onClose: () => void
  className?: string
}) => {
  const context = useDialogContainerContext()

  useInert(context.backgroundElement)

  if (!context.backgroundElement || !context.modalElement) return

  const element = (
    <div
      className={`${s.dialog} ${className ?? ""}`}
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel}
    >
      <div className={s.dimmer} aria-hidden="true" onClick={onClose} />
      {children}
    </div>
  )

  return createPortal(element, context.modalElement)
}

const useInert = (element: HTMLDivElement | null) => {
  useLayoutEffect(() => {
    if (!element) return

    element.setAttribute("inert", "true")
    return () => element.removeAttribute("inert")
  }, [element])
}
