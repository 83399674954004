import { useGraphqlQuery } from "graphql-mock"
import type { CSSProperties, ReactNode } from "react"
import { isColorContrastAccessible } from "utils"
import { query } from "./gql"

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return <div style={{ ...ThemeColors() }}>{children}</div>
}

export const ThemeColors = () => {
  const { data } = useGraphqlQuery(query, {})
  const { companyButtonColor, companyStarColor } = data?.currentCompany ?? {}

  const style = {
    "--company-star-color": companyStarColor,
    "--company-button-background-color": companyButtonColor,
    "--company-button-text-color":
      getAccessibleTextColorForBackgroundColor(companyButtonColor),
  } as CSSProperties

  return style
}

const getAccessibleTextColorForBackgroundColor = (
  backgroundColor: string | undefined
) => {
  if (backgroundColor === undefined) return ""

  if (isColorContrastAccessible(backgroundColor, "#FFFFFF")) return "#FFFFFF" // White
  if (isColorContrastAccessible(backgroundColor, "#4A4A4A")) return "#4A4A4A" // Coal
  if (isColorContrastAccessible(backgroundColor, "#171717")) return "#171717" // Carbon
  return "#000000" // Black
}
