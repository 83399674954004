import productImage from "assets/demo/productImage.svg"
import { mockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof productBundleFragment>

export const productBundleFragment = graphql(`
  fragment ProductBundleCard on ProductBundle {
    id
    title
    progress {
      starsMax
      starsCount
      certified
      certifiedMax
    }
    products(limit: 3) {
      pageInfo {
        totalItemCount
      }
      nodes {
        id
        image
      }
    }
  }
`)

export const mockProductBundleCardData = mockData<Data>({
  id: "1",
  title: "Lær god smaketeknikk",
  progress: {
    certified: false,
    starsCount: 10,
    certifiedMax: 5,
    starsMax: 15,
  },
  products: {
    pageInfo: {
      totalItemCount: 3,
    },
    nodes: times(3, (index) => ({
      id: index.toString(),
      image: productImage,
    })),
  },
})
