import avatarIcon from "assets/icons/avatarIcon.svg"
import attensiLogoBlue from "assets/logos/attensiLogoBlue.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query HeaderMenu($locale: String!) {
    currentUser {
      position
      avatar
      avatarTitle
      roles
    }
    currentCompany {
      companyFeatureFlags
      portalFeatureFlags
      selfRegistration
      name
      logoUrl
      portalSupportedLocales
      privacyPolicy(locale: $locale)
    }
  }
`)

export const mockHeaderMenuData = mockData<Data>({
  currentUser: {
    avatar: avatarIcon,
    avatarTitle: "Very fancy avatar title",
    position: "Manager",
    roles: [],
  },
  currentCompany: {
    companyFeatureFlags: [],
    portalFeatureFlags: [],
    logoUrl: attensiLogoBlue,
    name: "Attensi",
    selfRegistration: false,
    portalSupportedLocales: ["en-US"],
    privacyPolicy: null,
  },
})

registerMockData(query, mockHeaderMenuData())
