import type { ReactNode } from "react"
import { getChromaticProps } from "ui-tools"
import s from "./styles.module.scss"

export const ProductList = ({
  children,
  chromaticIgnore,
}: {
  children: ReactNode
  chromaticIgnore?: boolean
}) => {
  return (
    <ul className={s.productList} {...getChromaticProps(chromaticIgnore)}>
      {children}
    </ul>
  )
}
