import { useTranslation } from "react-i18next"
import { sanitizeHtml } from "ui-tools"
import type { Data } from "../Journey/gql"
import s from "./styles.module.scss"

export const JourneyDescription = ({ data }: { data: Data }) => {
  const { isLocked, description } = data

  return (
    <div className={s.journeyDescription}>
      {description && <Top data={data} />}

      {description && isLocked && (
        <div className={s.journeyDescription__line} />
      )}

      {isLocked && <Locked data={data} />}
    </div>
  )
}

const Top = ({ data }: { data: Data }) => {
  const { description, descriptionImageUrl } = data

  return (
    <div className={s.journeyDescriptionTop}>
      {descriptionImageUrl && (
        <img
          src={descriptionImageUrl}
          className={s.journeyDescriptionTop__image}
          alt=""
        />
      )}
      <div
        className={s.journeyDescriptionTop__text}
        dangerouslySetInnerHTML={sanitizeHtml(description ?? "", "allow-links")}
      />
    </div>
  )
}

const Locked = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { lockedBy } = data

  const lockedByTitle = lockedBy.nodes
    .map((category) => category.title)
    .join(", ")

  return (
    <div className={s.journeyDescriptionLocked}>
      {lockedByTitle && (
        <div className={s.journeyDescriptionLocked__lockedByTextWrapper}>
          {t("journey.LOCKED_BY_JOURNEY_TEXT_FIRST")}
          <span className={s.journeyDescriptionLocked__lockedByTitle}>
            {lockedByTitle}
          </span>
          {t("journey.LOCKED_BY_JOURNEY_TEXT_LAST")}
        </div>
      )}
    </div>
  )
}
