import type { Data } from "../Journey/gql"
import { JourneyCard } from "../JourneyCard"
import { JourneyCardBlock } from "./block"
import s from "./styles.module.scss"

export const JourneyProducts = ({ data }: { data: Data }) => {
  return (
    <div>
      <div className={s.startBlock} />
      <div>
        {data.products.nodes.map((product) => (
          <JourneyCardBlock key={product.id}>
            <JourneyCard data={product} isLocked={data.isLocked} />
          </JourneyCardBlock>
        ))}
      </div>
    </div>
  )
}
