import journeyBackground from "assets/demo/journeyBackground.jpeg"
import journeyDescriptionImage from "assets/demo/journeyDescriptionImage.svg"
import { mockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"
import { journeyCardFragment, mockJourneyCardData } from "../JourneyCard/gql"

export type Data = ResultOf<typeof journeyFragment>

export const journeyFragment = graphql(
  `
    fragment Journey on ProductCategory {
      title
      description
      descriptionImageUrl
      backgroundImageUrl
      isLocked
      progress {
        isCertified
        certifiedCount
        certifiedMax
      }
      lockedBy(limit: 1) {
        nodes {
          title
        }
      }
      unlocked(limit: 1) {
        nodes {
          title
          backgroundImageUrl
        }
      }
      products(limit: 20) {
        nodes {
          ...JourneyCard
        }
      }
    }
  `,
  [journeyCardFragment]
)

export const mockJourneyData = mockData<Data>({
  title: "Onboarding week one",
  isLocked: false,
  description:
    "Welcome to <strong>Supervisor journey</strong>, in your Store Manager will introduce you to your new role, provide a tour of the store, share job expectations and key responsibilities. Store Managers, please refer to the Manager Support.",
  descriptionImageUrl: journeyDescriptionImage,
  backgroundImageUrl: journeyBackground,

  progress: {
    isCertified: false,
    certifiedCount: 73,
    certifiedMax: 100,
  },

  lockedBy: {
    nodes: [
      {
        title: "Onboarding week two",
      },
    ],
  },

  unlocked: {
    nodes: [
      {
        title: "Onboarding week two",
        backgroundImageUrl: journeyBackground,
      },
    ],
  },

  products: {
    nodes: [
      mockJourneyCardData({
        progress: { certified: true, starsCount: 1, mandatory: true },
      }),
      mockJourneyCardData({
        progress: {
          certified: true,
          starsCount: 4,
          starsMax: 5,
          mandatory: true,
        },
        productType: "skill",
      }),
      mockJourneyCardData({
        progress: { certified: true, mandatory: true },
        productType: "activity",
      }),
      mockJourneyCardData({
        progress: { certified: false, mandatory: true },
        hasPlayedXapi: true,
        productType: "xapi",
      }),
      mockJourneyCardData({
        progress: { starsCount: 0, starsMax: 5, mandatory: true },
        productType: "skill",
      }),
      mockJourneyCardData({}),
      mockJourneyCardData({
        progress: { certified: true, starsCount: 1, mandatory: true },
      }),
      mockJourneyCardData({
        progress: {
          certified: true,
          starsCount: 4,
          starsMax: 5,
          mandatory: true,
        },
        productType: "skill",
      }),
      mockJourneyCardData({
        productType: "activity",
      }),
      mockJourneyCardData({
        progress: { certified: false, mandatory: true },
        hasPlayedXapi: true,
        productType: "xapi",
      }),
      mockJourneyCardData({
        progress: { starsCount: 0, starsMax: 5, mandatory: true },
        productType: "skill",
      }),
      mockJourneyCardData({}),
      mockJourneyCardData({
        progress: { certified: true, starsCount: 1, mandatory: true },
      }),
      mockJourneyCardData({
        progress: {
          certified: true,
          starsCount: 4,
          starsMax: 5,
          mandatory: true,
        },
        productType: "skill",
      }),
      mockJourneyCardData({
        productType: "activity",
      }),
      mockJourneyCardData({
        progress: { certified: false, mandatory: true },
        hasPlayedXapi: true,
        productType: "xapi",
      }),
      mockJourneyCardData({
        progress: { starsCount: 0, starsMax: 5, mandatory: true },
        productType: "skill",
      }),
      mockJourneyCardData({}),
    ],
  },
})
