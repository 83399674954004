import { useAsyncData } from "hooks"
import { auth } from "./index"
import type { Session } from "./types/Session"

type Return<T> =
  | {
      activeSession: undefined
      sessions: undefined
      isPending: true
      error: undefined
    }
  | {
      activeSession: T
      sessions: T[]
      isPending: false
      error: undefined
    }
  | {
      activeSession: undefined
      sessions: undefined
      isPending: false
      error: Error
    }

export const useAuth = () => {
  const { data, isPending, error } = useAsyncData(async () => {
    const activeSession = await auth.getActiveSession()
    const sessions = await auth.getSessions()

    return { sessions, activeSession }
  }, [])

  return {
    activeSession: data?.activeSession,
    sessions: data?.sessions,
    isPending,
    error,
  } as Return<Session>
}
