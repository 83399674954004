import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { sortBy } from "lodash-es"
import { useWindowWidth } from "materia"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { custom } from "../../../bonzai/bonzai"
import { toggleActivityItem } from "../../../bonzai/toggleActivityItem"
import { createPlaythrough } from "../../../graphql/createPlaythrough"
import { usePickText } from "../../../i18n/usePickText"
import { ProductModalActivity } from "./ProductModalActivity"

type ProductModalActivityLoaderProps = {
  productId: number
  onClose: () => void
}
export const ProductModalActivityLoader = (
  props: ProductModalActivityLoaderProps
) => (
  <QueryBoundary
    fallback={<ProductModalActivity.Skeleton />}
    isSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId, onClose }: ProductModalActivityLoaderProps) => {
  const pickText = usePickText()
  const { t } = useTranslation()

  const [status, product, tasks] = useData(productId)

  const isCertified = status.certified
  const numberOfTasks = tasks.length
  const numberOfCompletedTasks = tasks.filter((item) => item.done).length
  const completedIfCertified = isCertified
    ? numberOfTasks
    : numberOfCompletedTasks

  const isLocked = status.stars > 0
  const isBottomVisible = numberOfCompletedTasks >= numberOfTasks && !isLocked

  const taskElements = tasks.map((task) => (
    <ProductModalActivity.Task
      key={task.id}
      title={pickText(task.title)}
      isChecked={task.done}
      isLocked={isLocked}
      onClick={() => toggleActivityItem(productId, task)}
    />
  ))

  const onConfirm = () => {
    onClose()
    createPlaythrough(String(productId))
  }

  return (
    <ProductModalActivity>
      <ProductModalActivity.Top
        isLocked={isLocked}
        completedCount={completedIfCertified}
        totalCount={numberOfTasks}
      />
      <ProductModalActivity.Tasks>{taskElements}</ProductModalActivity.Tasks>
      <ProductModalActivity.Bottom isVisible={isBottomVisible}>
        <ProductModalActivity.WaitingForApproval
          isVisible={product.manager_approval}
          title={t("activity.PENDING_APPROVAL")}
          tooltipTitle={t("activity.PENDING_APPROVAL_HELP_TITLE")}
          tooltipDescription={t("activity.PENDING_APPROVAL_HELP_DESCRIPTION")}
        />
        <ProductModalActivity.ConfirmButton
          isVisible={!product.manager_approval}
          confirmText={t("activity.CONFIRM_COMPLETE")}
          onClick={onConfirm}
        />
      </ProductModalActivity.Bottom>
    </ProductModalActivity>
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    custom.getProductProgress.useSuspenseQuery([
      productId,
      { apiVersion: "v1" },
    ]),

    v3.getProduct.useSuspenseQuery([productId], {
      select: (res) => res.data,
    }),

    v3.getActivityItems.useSuspenseQuery([productId], {
      select: (res) => sortBy(res.data, (item) => item.id),
    }),
  ])
}
