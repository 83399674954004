import { times } from "lodash-es"
import { ProductCategoryProductsLoading } from "../ProductCategoryProducts/loading"
import s from "./styles.module.scss"

export const ProductCategoriesLoading = ({ count }: { count: number }) => {
  return times(count, (index) => <ProductCategoryLoading key={index} />)
}

const ProductCategoryLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__title} />
      <ProductCategoryProductsLoading count={6} />
    </div>
  )
}
