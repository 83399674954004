import diplomaMedalLocked from "assets/illustrations/diplomaMedalLocked.svg"
import diplomaRibbonLocked from "assets/illustrations/diplomaRibbonLocked.svg"

import s from "./DiplomaPreview.module.scss"

export const DiplomaPreviewLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__top}>
        <img src={diplomaMedalLocked} alt="" />
        <img src={diplomaRibbonLocked} alt="" />
      </div>

      <div className={s.skeleton__bottom}>
        <div className={s.skeleton__loader} />
        <div className={s.skeleton__loader} />
        <div className={s.skeleton__loader} />
        <div className={s.skeleton__loader} />
      </div>
    </div>
  )
}
