import { Card } from "materia"
import s from "./styles.module.scss"

export const NewsWidgetLoading = () => {
  const newsItem = (
    <li className={s.skeleton__item}>
      <div className={s.skeleton__itemTitle} />
      <div className={s.skeleton__itemImage} />
      <div className={s.skeleton__itemDescription} />
    </li>
  )
  return (
    <Card borderRadius="24">
      <div className={s.skeleton}>
        <div className={s.skeleton__header}>
          <div className={s.skeleton__headerImage} />
          <div className={s.skeleton__headerTitle} />
        </div>

        <ul className={s.skeleton__list}>
          {newsItem}
          {newsItem}
          {newsItem}
        </ul>
      </div>
    </Card>
  )
}
