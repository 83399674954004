import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"
import {
  mockProductModalButtonsData,
  productModalButtonsFragment,
} from "./ProductModalButtons/gql"
import {
  mockProductModalMainData,
  productModalMainFragment,
} from "./ProductModalMain/gql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query ProductModal($productId: ID!, $platform: Platform) {
      currentCompany {
        portalFeatureFlags
      }
      product(id: $productId) {
        isLocked
      }
      productModalMain: product(id: $productId) {
        ...ProductModalMain
      }
      productModalButtons: product(id: $productId) {
        ...ProductModalButtons
      }
    }
  `,
  [productModalMainFragment, productModalButtonsFragment]
)

export const mockProductModalData = mockData<Data>({
  currentCompany: {
    portalFeatureFlags: [],
  },
  product: {
    isLocked: false,
  },
  productModalMain: mockProductModalMainData(),
  productModalButtons: mockProductModalButtonsData(),
})

registerMockData(query, mockProductModalData())
