import type { FetchQuery } from "./fetchQuery"
import { queryClient } from "./queryClient"
import type { FnBase } from "./types"
import { getArgs } from "./utils/getArgs"

export const getFetchQueryOnce = (
  fn: FnBase,
  path: string[]
): FetchQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const key = [...path, ...args]
    const [options] = rest

    return queryClient.fetchQuery({
      staleTime: Infinity,
      retry: false,
      ...options,
      queryKey: key,
      queryFn: () => fn(...args),
    })
  }
}
