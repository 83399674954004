import newsCardIllustration from "assets/illustrations/newsCardIllustration.svg"
import s from "./styles.module.scss"

import { useGraphqlQuery } from "graphql-mock"
import { useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { ErrorPopup } from "utility-components"
import { HiddenHeader } from "../../components/HiddenHeader"
import { getElapsedTime } from "../../dataUtilities/getElapsedTime"
import { usePageTitle } from "../../tools/usePageTitle"
import { NewsCard } from "./NewsCard/NewsCard"
import { query } from "./gql"
import { NewsViewLoading } from "./loading"

export const NewsView = () => {
  const { t, i18n } = useTranslation()

  useScrollToTopOnMount()
  usePageTitle(t("pageTitle.NEWS"))

  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <NewsViewLoading />
  if (error) return <ErrorPopup error={error} />

  const newsPosts = data.newsPosts.nodes
  const isEmpty = newsPosts.length === 0

  if (isEmpty) {
    return <Empty description={t("notFound.NOTFOUND_NEWS")} />
  }

  return (
    <div className={s.view}>
      <HiddenHeader title={t("navigation.NEWS")} tag="h1" />
      <ul className={s.view__posts}>
        {newsPosts.map((post) => (
          <NewsCard
            key={post.id}
            link={{ to: `/news/post/${post.id}` }}
            title={post.headline ?? ""}
            displayDate={getElapsedTime({
              date: post.updatedAt,
              locale: i18n.language,
            })}
            image={post.image ?? ""}
            description={post.body ?? ""}
            numberOfLikes={post.likeCount}
            readMoreText={t("news.READ_MORE")}
            isLiked={post.isLiked}
            newsPostId={post.id}
          />
        ))}
      </ul>
    </div>
  )
}

type EmptyProps = {
  description: string
}
const Empty = ({ description }: EmptyProps) => (
  <div className={s.empty}>
    <img src={newsCardIllustration} className={s.empty__illustration} alt="" />
    <div className={s.empty__text}>{description}</div>
  </div>
)
