import { initGraphQLTada } from "gql.tada"
import type { introspection } from "./generated/graphql-env"
export type * from "./generated/graphql.types"
export { graphqlRequest } from "./graphqlRequest"

export const graphql = initGraphQLTada<{
  introspection: introspection
  scalars: {
    ISO8601DateTime: string
    ID: string
  }

  // In my humble opinion:
  // Fragment masking makes the types more confusing.
  // It adds an extra step as you need to decode the type before you use it.
  // The benefits seem minor compared to the extra complexity.
  // https://gql-tada.0no.co/guides/fragment-colocation
  disableMasking: true
}>()
