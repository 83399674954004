import { Transition } from "@headlessui/react"
import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import leaderboardColorIcon from "assets/icons/leaderboardColorIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup } from "utility-components"
import { query } from "./gql"
import { useConfettiBannerStore } from "./store"
import s from "./styles.module.scss"

export const ConfettiBanner = () => {
  const { t } = useTranslation()

  const { isVisible, progressChange } = useConfettiBannerStore()

  const { data, error, isPending } = useGraphqlQuery(
    query,
    { id: progressChange?.productId! },
    { enabled: progressChange !== undefined }
  )

  if (isPending) return
  if (error) return <ErrorPopup error={error} />

  if (progressChange === undefined) return

  const isHighScoreType = progressChange.type === "high_score"

  const icon = isHighScoreType ? leaderboardColorIcon : checkmarkCircleGreen
  const label = isHighScoreType
    ? `${progressChange.highScore} ${t("winningBox.PRODUCT_SIM_SCORE")}`
    : t("winningBox.PRODUCT_NONSIM_COMPLETED")

  return (
    <Transition
      show={isVisible}
      aria-live="polite"
      className={modifiers(s, "banner", progressChange.type)}
      enterFrom={s["banner--enterFrom"]}
      enterTo={s["banner--enterTo"]}
      leaveTo={s["banner--leaveTo"]}
      appear
    >
      <Card>
        <div className={s.banner__container}>
          <img className={s.banner__icon} src={icon} alt="" />
          <div className={s.banner__label}>{label}</div>
          <div className={s.banner__title}>{data.product.title}</div>
        </div>
      </Card>
    </Transition>
  )
}
