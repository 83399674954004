import closeIcon from "assets/icons/closeIconWhite.svg"
import descriptionIcon from "assets/icons/journeyDescriptionIcon.svg"
import { useHasValueChanged, useToggle } from "hooks"
import { backgroundImageStyle, modifiers } from "ui-tools"
import { JourneyDescription } from "../JourneyDescription"
import { JourneyHeader } from "../JourneyHeader"
import { JourneyProducts } from "../JourneyProducts"
import { JourneyUnlockedModal } from "../JourneyUnlockedModal"
import { Content } from "./content"
import type { Data } from "./gql"
import s from "./styles.module.scss"

export const Journey = ({
  data,
  initialIsDescriptionOpen,
}: {
  data: Data
  initialIsDescriptionOpen?: boolean
}) => {
  const { progress, isLocked, description, backgroundImageUrl } = data

  const [isOpen, toggleIsOpen] = useToggle(!progress.isCertified)
  const [isDescriptionOpen, toggleIsDescriptionOpen] = useToggle(
    initialIsDescriptionOpen ?? isLocked
  )
  const enableAnimations = useHasValueChanged(isLocked)

  const descriptionHasContent = isLocked || Boolean(description)
  const isDescriptionVisible = isDescriptionOpen && descriptionHasContent

  return (
    <>
      <div
        className={modifiers(s, "journey", {
          isLocked,
          enableAnimations,
          isDescriptionVisible,
        })}
        style={backgroundImageStyle(backgroundImageUrl ?? "")}
      >
        <div className={s.journey__overlay} onClick={toggleIsDescriptionOpen} />

        <JourneyHeader data={data} isOpen={isOpen} onClick={toggleIsOpen} />

        <Content isOpen={isOpen}>
          {descriptionHasContent && (
            <DescriptionIcon
              isDescriptionVisible={isDescriptionVisible}
              onClick={toggleIsDescriptionOpen}
            />
          )}

          {isDescriptionVisible && <JourneyDescription data={data} />}

          <JourneyProducts data={data} />
        </Content>
      </div>

      <JourneyUnlockedModal data={data} />
    </>
  )
}

const DescriptionIcon = ({
  onClick,
  isDescriptionVisible,
}: {
  onClick: () => void
  isDescriptionVisible: boolean
}) => {
  const icon = isDescriptionVisible ? closeIcon : descriptionIcon

  return (
    <div
      className={modifiers(s, "journeyDescriptionIcon", {
        isDescriptionVisible,
      })}
    >
      <button onClick={onClick} title="Toggle journey description">
        <div className={s.journeyDescriptionIcon__background}>
          <img className={s.journeyDescriptionIcon__image} src={icon} alt="" />
        </div>
      </button>
    </div>
  )
}
