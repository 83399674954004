import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import { formatDate } from "format"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaImage, FiestaLink } from "utility-components"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import { Badge } from "../../Badge"
import { Deadline } from "../../Deadline"
import { Stars } from "../../Stars"
import type { Course, Data } from "../gql"
import { ProductCardShell } from "../shell"
import s from "./styles.module.scss"

export const ProductCardEvent = ({ data }: { data: Data }) => {
  const { id, title, progress, isLocked, image } = data

  return (
    <li>
      <ProductCardShell>
        <FiestaLink
          className={modifiers(s, "card", { isLocked })}
          to={getProductLink(id, "meet")}
          aria-haspopup="dialog"
          replace={true}
        >
          <FiestaImage
            src={image}
            alt="event"
            sizes="(width = small) 100px, (width = medium) 33vw, 200px"
            className={s.card__image}
          />
          <CertifiedOrDeadline data={data} />
          {progress.mandatory && <Mandatory />}
          <Info data={data} />
          <DateInfo data={data} />
          <h3 className={s.card__title}>{title}</h3>
          <div className={s.card__stars}>
            <Stars
              count={progress.starsCount}
              max={progress.starsMax}
              isLocked={isLocked}
            />
          </div>
        </FiestaLink>
      </ProductCardShell>
    </li>
  )
}

const CertifiedOrDeadline = ({ data }: { data: Data }) => {
  const { progress, deadlineOccurrence } = data
  const status = deadlineOccurrence?.status

  if (status === "due")
    return (
      <div className={s.deadline}>
        <Deadline variant="dueDate" size="small" />
      </div>
    )
  if (status === "overdue")
    return (
      <div className={s.deadline}>
        <Deadline variant="overdue" size="small" />
      </div>
    )
  if (progress.certified) return <Certified />
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="Completed" />
)

const Mandatory = () => {
  const { t } = useTranslation()

  return (
    <div className={s.mandatory}>
      <Badge title={t("user.MANDATORY")} size="small" />
    </div>
  )
}

const Info = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { progress, course } = data

  const isCertified = progress.certified
  const isAttending = course?.isAttending
  const isFullyBooked = course?.isFull
  const isOnWaitingList = course?.isOnWaitingList
  const isEmpty = course === null

  const getInfoText = () => {
    if (!course) return t("event.NO_DATES_AVAILABLE")
    if (course.isAttending) return t("event.ATTENDING")
    if (course.isOnWaitingList) return t("event.ON_WAITING_LIST")
    if (progress.certified) return t("event.COMPLETED")
    if (course.isFull) return t("event.FULLY_BOOKED")
    return t("product.PRODUCT_TYPES.meet")
  }

  return (
    <div
      className={modifiers(s, "info", {
        isCertified,
        isAttending,
        isFullyBooked,
        isEmpty,
        isOnWaitingList,
      })}
    >
      {getInfoText()}
    </div>
  )
}

const DateInfo = ({ data }: { data: Data }) => {
  const { i18n } = useTranslation()

  const { course, progress } = data

  if (!course) return null

  const isCompleted = progress.certified

  return (
    <div className={s.date}>
      <div
        className={modifiers(s, "date__month", {
          isCompleted,
        })}
      >
        {formatCourseMonth(course, i18n.language)}
      </div>
      <div
        className={modifiers(s, "date__day", {
          isCompleted,
        })}
      >
        {formatCourseDay(course)}
      </div>
      <div
        className={modifiers(s, "date__time", {
          isCompleted,
        })}
      >
        {formatCourseStartTime(course, i18n.language)}
      </div>
    </div>
  )
}

const formatCourseDay = (course: Course) => {
  const date = new Date(course.startDatetime)
  // Didn't use intl here since we didn't like the periods added to norwegian dates.
  return date.getDate().toString()
}

const formatCourseMonth = (course: Course, locale: string) => {
  return formatDate(course.startDatetime, locale, {
    month: "short",
  })
}

const formatCourseStartTime = (course: Course, locale: string) => {
  return formatDate(course.startDatetime, locale, {
    hour: "numeric",
    minute: "numeric",
  })
}
