import Plausible from "plausible-tracker"
import { APP_ENV } from "../env"

export const PLAUSIBLE_CONFIG = {
  trackLocalhost: false,
  apiHost: "https://plausible.attensi.com",
  domain: "portal.attensi.com",
}

export const initializePlausible = () => {
  if (APP_ENV !== "production") return

  const plausible = Plausible(PLAUSIBLE_CONFIG)

  plausible.enableAutoPageviews()
}
