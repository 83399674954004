import {
  useMutation,
  type MutateOptions,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query"
import type { FnBase, FnParams, FnReturn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options<Fn extends FnBase> = MutateOptions<
  FnReturn<Fn>,
  unknown,
  FnParams<Fn>
>

type Return<Fn extends FnBase> = Omit<
  UseMutationResult<FnReturn<Fn>, unknown, FnParams<Fn>>,
  "mutate" | "mutateAsync"
> & {
  mutate: MutateFn<Fn, void>
  mutateAsync: MutateFn<Fn, Promise<FnReturn<Fn>>>
}

type MutateFn<Fn extends FnBase, ReturnValue> = (
  args: FnParams<Fn>,
  options?: Options<Fn>
) => ReturnValue

export type UseMutation<Fn extends FnBase> = (
  options?: UseMutationOptions<FnReturn<Fn>, unknown, FnParams<Fn>>
) => Return<Fn>

export const getUseMutation = (fn: FnBase): UseMutation<FnBase> => {
  return (options) => {
    const res = useMutation({
      ...options,
      mutationFn: (args) => fn(...args),
    })

    const mutate = (...input: any[]) => {
      const { args, rest } = getArgs(input)
      return res.mutate(args, ...rest)
    }

    const mutateAsync = (...input: any[]) => {
      const { args, rest } = getArgs(input)
      return res.mutateAsync(args, ...rest)
    }

    return { ...res, mutate, mutateAsync }
  }
}
