import type { FetchQueryOptions } from "@tanstack/react-query"
import { queryClient } from "./queryClient"
import type { FnBase, FnParams, FnReturn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options<Fn extends FnBase> = FetchQueryOptions<FnReturn<Fn>>
type Return<Fn extends FnBase> = Promise<FnReturn<Fn>>

export type FetchQuery<Fn extends FnBase> = (
  args: FnParams<Fn>,
  options?: Options<Fn>
) => Return<Fn>

export const getFetchQuery = (
  fn: FnBase,
  path: string[]
): FetchQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const key = [...path, ...args]
    return queryClient.fetchQuery({
      ...rest,
      queryKey: key,
      queryFn: () => fn(...args),
    })
  }
}
