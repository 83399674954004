import type { ReactNode } from "react"
import { modifiers } from "ui-tools"
import s from "./Card.module.scss"

type CardProps = {
  children?: ReactNode
  borderRadius?: "12" | "16" | "24" | "32"
  transparent?: boolean
}
export const Card = ({
  children,
  borderRadius = "16",
  transparent,
}: CardProps) => (
  <div className={modifiers(s, "card", `border-radius-${borderRadius}`)}>
    <div className={modifiers(s, "card__content", { transparent })}>
      {children}
    </div>
  </div>
)
