import { Card } from "materia"
import type { PropsWithChildren } from "react"
import s from "./styles.module.scss"

/**
 * Shell scales exactly the same as ProductCard.
 * Use this component for other cards so they're all the same size.
 */
export const ProductCardShell = ({ children }: PropsWithChildren) => (
  <Card>
    <div className={s.shell}>
      <div className={s.shell__background}>
        <div className={s.shell__image} />
        <div className={s.shell__bottom}>
          <div className={s.shell__type}>&nbsp;</div>
          <div className={s.shell__title}>&nbsp;</div>
          <div className={s.shell__stars} />
        </div>
      </div>
      <div className={s.shell__content}>{children}</div>
    </div>
  </Card>
)
