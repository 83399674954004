import { useScrollToTopOnMount } from "hooks"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { useNavigateBack } from "../../tools/useNavigateBack"
import { BundleProducts } from "./BundleProducts"
import s from "./styles.module.scss"

export const ProductBundleView = ({ id }: { id: string }) => {
  useScrollToTopOnMount()

  return (
    <div className={s.productBundleView}>
      <BundleProducts id={id} />
      <Back />
    </div>
  )
}

const Back = () => {
  const { t } = useTranslation()
  const navigateBack = useNavigateBack()

  return (
    <Card borderRadius="12">
      <button className={s.back} onClick={() => navigateBack()}>
        {t("actions.BACK")}
      </button>
    </Card>
  )
}
