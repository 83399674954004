import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["products"]["nodes"][number]

export const query = graphql(`
  query UserProfileCertificates($limit: Int!, $page: Int) {
    products(input: { variant: with_certificate }, limit: $limit, page: $page) {
      pageInfo {
        hasNextPage
        nextPage
        nextPageItemCount
      }
      nodes {
        id
        progress {
          certified
        }
        diplomaConfiguration {
          title
        }
      }
    }
  }
`)

export const mockUserProfileCertificatesData = mockData<Data>({
  products: {
    pageInfo: {
      hasNextPage: false,
      nextPage: null,
      nextPageItemCount: 0,
    },
    nodes: times(3, (i) => ({
      id: i.toString(),
      progress: {
        certified: true,
      },
      diplomaConfiguration: {
        title: `Certificate #${i + 1}`,
      },
    })),
  },
})

registerMockData(query, mockUserProfileCertificatesData())
