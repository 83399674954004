import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../graphql"
import { mockProductCardData, productCardFragment } from "../ProductCard/gql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query FilteredProducts($input: ProductsInput, $limit: Int!, $page: Int) {
      products(input: $input, limit: $limit, page: $page) {
        pageInfo {
          nextPage
          nextPageItemCount
        }
        nodes {
          ...ProductCard
        }
      }
    }
  `,
  [productCardFragment]
)

export const mockFilteredProductsData = mockData<Data>({
  products: {
    pageInfo: {
      nextPage: null,
      nextPageItemCount: 0,
    },
    nodes: times(18, () => mockProductCardData()),
  },
})

registerMockData(query, mockFilteredProductsData())
