import journeyBackground from "assets/demo/journeyBackground.jpeg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type Journey = NonNullable<Data["nextJourney"]>
export type Progress = Journey["progress"]

export const query = graphql(`
  query JourneyTeaser {
    nextJourney {
      id
      title
      backgroundImageUrl
      progress {
        certifiedMax
        certifiedCount
        starsCount
        starsMax
      }
    }
  }
`)

export const mockJourneyTeaserData = mockData<Data>({
  nextJourney: {
    id: "1",
    title: "Onboarding",
    backgroundImageUrl: journeyBackground,
    progress: {
      certifiedCount: 4,
      certifiedMax: 8,
      starsCount: 6,
      starsMax: 12,
    },
  },
})

registerMockData(query, mockJourneyTeaserData())
