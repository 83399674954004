import addToCalendarIcon from "assets/icons/addToCalendar.svg"
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrowDown.svg"
import pinIcon from "assets/icons/pin.svg"
import waitingList from "assets/icons/waitingList.svg"
import scaredBallonPerson from "assets/illustrations/scaredBallonPerson.svg"
import { Button, useWindowWidth } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { ProductModal } from ".."
import s from "./ProductModalEvent.module.scss"

export const ProductModalEvent = ({ children }: PropsWithChildren) => (
  <ProductModal.Side>
    <div className={s.modal}>{children}</div>
  </ProductModal.Side>
)

const Header = ({ text, color }: { text: string; color?: "red" }) => (
  <h3 className={modifiers(s, "header", color)}>{text}</h3>
)

const Ticket = ({
  isWaiting,
  attendingText,
  date,
  time,
}: {
  isWaiting: boolean
  attendingText: string
  date: string
  time: string
}) => (
  <div className={modifiers(s, "ticket", { isWaiting })}>
    <div className={s.ticket__attending}>{attendingText}</div>
    <div className={s.ticket__date}>{date}</div>
    <div className={s.ticket__time}>{time}</div>
  </div>
)

const DateButtons = ({ children }: PropsWithChildren) => (
  <div className={s.dateButtons}>{children}</div>
)

const DateButton = ({
  color,
  text,
  onClick,
}: {
  text: string
  color: "blue" | "white"
  onClick: () => void
}) => (
  <button onClick={onClick} className={modifiers(s, "dateButton", color)}>
    {text}
    <ArrowDownIcon />
  </button>
)

const AddToCalendar = ({
  text,
  onClick,
}: {
  text: string
  onClick: () => void
}) => (
  <button className={s.dateButtons__calendar} onClick={onClick}>
    <img src={addToCalendarIcon} alt="" />
    {text}
  </button>
)

const Dates = ({
  isOverflow,
  children,
}: {
  isOverflow: boolean
  children: ReactNode
}) => <ul className={modifiers(s, "dates", { isOverflow })}>{children}</ul>

const Date = ({
  isActive,
  onClick,
  month,
  day,
  time,
  isCancelled,
}: {
  month: string
  day: string
  time: string
  isActive: boolean
  isCancelled: boolean
  onClick: () => void
}) => {
  return (
    <li className={s.dateListItem}>
      <button
        onClick={onClick}
        className={modifiers(s, "date", { isActive, isCancelled })}
      >
        <div className={s.date__month}>{month}</div>
        <div className={s.date__day}>{day}</div>
        <div className={s.date__time}>{time}</div>
      </button>
    </li>
  )
}

const ToggleShowMore = ({
  buttonText,
  onClick,
}: {
  buttonText: string
  onClick: () => void
}) => {
  const { isBigWindow } = useWindowWidth()
  if (!isBigWindow) return null

  return (
    <li className={s.toggleShowMore}>
      <button className={s.toggleShowMoreButton} onClick={onClick}>
        {buttonText}
      </button>
    </li>
  )
}

const WaitingInfo = ({ text }: { text: string }) => (
  <div className={s.waitingInfo}>
    <div className={s.waitingInfo__text}>{text}</div>
    <img className={s.waitingInfo__icon} src={waitingList} alt="" />
  </div>
)

const InfoList = ({
  isOpaque,
  isUrl,
  children,
  address,
}: {
  children: ReactNode
  isOpaque?: boolean
  address: string
  isUrl: boolean
}) => (
  <div className={modifiers(s, "infoList", { isOpaque })}>
    <div className={s.infoList__address}>
      <img className={s.infoList__addressIcon} src={pinIcon} alt="" />
      <div className={s.infoList__addressValue}>
        {isUrl ? (
          <a
            className={s.infoList__url}
            href={address}
            target="_blank"
            rel="noreferrer"
          >
            {address}
          </a>
        ) : (
          address
        )}
      </div>
    </div>
    <div className={s.infoList__wrapper}>{children}</div>
  </div>
)

const Info = ({
  icon,
  value,
  label,
  color,
}: {
  icon: string
  label: string
  color?: "red"
  value: string
}) => (
  <div className={modifiers(s, "info", color)}>
    <img className={s.info__icon} src={icon} alt="" />
    <div className={s.info__label}>{label}</div>
    <div className={s.info__value}>{value}</div>
  </div>
)

const Buttons = ({ children }: PropsWithChildren) => (
  <ProductModal.ActionButtons>
    <div className={s.buttons}>{children}</div>
  </ProductModal.ActionButtons>
)

const EventButton = ({
  variant,
  children,
  onClick,
}: {
  variant: "attend" | "unregister" | "waiting-list" | "disabled"
  onClick: () => void
  children: ReactNode
}) => {
  return (
    <>
      {variant === "attend" ? (
        <Button borderRadius="12" size="big" onClick={onClick}>
          {children}
        </Button>
      ) : (
        <button onClick={onClick} className={modifiers(s, "button", variant)}>
          {children}
        </button>
      )}
    </>
  )
}

const Empty = ({ header }: { header: string }) => (
  <div className={s.empty}>
    <h3 className={s.empty__header}>{header}</h3>
    <img className={s.empty__image} src={scaredBallonPerson} alt="" />
  </div>
)

ProductModalEvent.Header = Header
ProductModalEvent.Ticket = Ticket
ProductModalEvent.DateButtons = DateButtons
ProductModalEvent.DateButton = DateButton
ProductModalEvent.Dates = Dates
ProductModalEvent.AddToCalendar = AddToCalendar
ProductModalEvent.Date = Date
ProductModalEvent.ToggleShowMore = ToggleShowMore
ProductModalEvent.WaitingInfo = WaitingInfo
ProductModalEvent.InfoList = InfoList
ProductModalEvent.Info = Info
ProductModalEvent.Buttons = Buttons
ProductModalEvent.EventButton = EventButton
ProductModalEvent.Empty = Empty
