import {
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
  type UseSuspenseQueryResult,
} from "@tanstack/react-query"
import type { FnBase, FnParams, FnReturn } from "./types"
import { getIsRunningQueryAll } from "./useQueryAll"
import { getArgs } from "./utils/getArgs"
import { isPromise } from "./utils/isPromise"

type BaseInitialData<Fn extends FnBase> =
  | FnReturn<Fn>
  | (() => FnReturn<Fn>)
  | undefined

type Options<Fn extends FnBase, TData, InitialData> = Omit<
  UseSuspenseQueryOptions<FnReturn<Fn>, Error, TData>,
  "queryFn" | "queryKey" | "initialData"
> & {
  initialData?: InitialData
}

export type UseSuspenseQuery<Fn extends FnBase> = <
  TData = FnReturn<Fn>,
  InitialData extends BaseInitialData<Fn> = undefined
>(
  args: FnParams<Fn>,
  options?: Options<Fn, TData, InitialData>
) => UseSuspenseQueryResult<TData>["data"]

export const getUseSuspenseQuery = (
  fn: FnBase,
  path: string[]
): UseSuspenseQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const [options] = rest
    const key = [...path, ...args]

    try {
      const result = useSuspenseQuery({
        throwOnError: true,
        ...options,
        queryKey: key,
        queryFn: () => fn(...args),
      })
      return result.data
    } catch (error) {
      if (isPromise(error) && getIsRunningQueryAll()) {
        return error as any
      }

      throw error
    }
  }
}
