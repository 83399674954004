import { ERROR_NAME } from "./constants"
import type { QueryParams } from "./types"

type CreateFetchErrorProps = {
  message: string | undefined
  method: string
  url: string
  cause: Error | undefined
  status: number | undefined
  text: string | undefined
  json: unknown | undefined
}

export class FetchError extends Error {
  name = ERROR_NAME
  isOnline = window.navigator.onLine

  url: string
  scrubbedUrl: string
  method: string
  status: number | undefined
  params: QueryParams | undefined
  text: string | undefined
  json: unknown | undefined
  didRequestFail: boolean

  constructor({
    message,
    url,
    method,
    cause,
    status,
    text,
    json,
  }: CreateFetchErrorProps) {
    const parsedMessage = parseMessage(message, url, status)
    super(parsedMessage, { cause })

    this.didRequestFail = checkIfRequestFailed(message, status)
    this.url = url
    this.scrubbedUrl = scrubUrl(url)
    this.method = method
    this.status = status
    this.text = text
    this.json = json
    this.params = parseParams(url)
  }
}

const parseMessage = (
  message: string | undefined,
  url: string,
  status: number | undefined
) => {
  const scrubbedUrl = scrubUrl(url)

  if (message === undefined) {
    return `Request failed. Status: ${status}. URL: ${scrubbedUrl}`
  }

  if (checkIfRequestFailed(message, status)) {
    return `Request failed. URL: ${scrubbedUrl}`
  }

  return message
}

const checkIfRequestFailed = (
  message: string | undefined,
  status: number | undefined
) => {
  const chromeMessage = "Failed to fetch"
  const safariMessage = "Load failed"

  return (
    message === chromeMessage ||
    message === safariMessage ||
    status === undefined
  )
}

const parseParams = (url: string | undefined) => {
  if (url === undefined) return undefined

  try {
    const entries = new URL(url).searchParams.entries()
    return Object.fromEntries(entries)
  } catch {
    return undefined
  }
}

const scrubUrl = (text: string) => {
  const numberSegment = /\/\d+/g
  const longSegment = /\/[^./]{20,}/g
  return text.replace(numberSegment, "/{number}").replace(longSegment, "/{...}")
}
