import s from "./styles.module.scss"

export const JourneyHeaderLoading = () => {
  return (
    <div className={s.loading}>
      <div className={s.loading__headerButton}>
        <div className={s.loading__progress} />
        <div className={s.loading__title} />
        <div className={s.loading__toggle} />
      </div>
    </div>
  )
}
