import { times } from "lodash-es"
import { ProductCardLoading } from "../../../components/ProductCard/loading"
import { ProductList } from "../../../components/ProductList"

export const ProductCategoryProductsLoading = ({
  count,
}: {
  count: number
}) => {
  return (
    <ProductList>
      {times(count, (index) => (
        <ProductCardLoading key={index} />
      ))}
    </ProductList>
  )
}
