import { NewsCard } from "./NewsCard/NewsCard"
import s from "./styles.module.scss"

export const NewsViewLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__posts}>
        <NewsCard.Skeleton />
        <NewsCard.Skeleton />
        <NewsCard.Skeleton />
      </div>
    </div>
  )
}
