import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>
export type NewsPost = Data["newsPost"]

export const query = graphql(`
  query NewsPostView($id: ID!) {
    newsPost(id: $id) {
      id
      headline
      body
      image
      likeCount
      isLiked
      updatedAt
    }
  }
`)

export const mockNewsPostViewData = mockData<Data>({
  newsPost: {
    id: "1",
    headline: "Attensi Music Video",
    body: "Click here to see the video",
    image: productImage,
    likeCount: 15,
    isLiked: false,
    updatedAt: new Date().toISOString(),
  },
})

registerMockData(query, mockNewsPostViewData())
