import { ReactComponent as LockedIcon } from "assets/icons/lock.svg"
import { ReactComponent as StarEmptyIcon } from "assets/icons/starEmptyIcon.svg"
import { ReactComponent as StarThemeIcon } from "assets/icons/starThemeIcon.svg"
import { times } from "lodash-es"
import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

type StarSize = "extraSmall" | "small" | "medium" | "large"

export const Stars = ({
  count,
  max,
  size = "medium",
  isLocked,
  isProductModalLocked,
}: {
  count: number
  max: number
  isLocked?: boolean
  size?: StarSize
  isProductModalLocked?: boolean | undefined
}) => {
  if (isLocked)
    return <Locked isProductModalLocked={isProductModalLocked} size={size} />

  return (
    <div
      className={modifiers(s, "star", size)}
      aria-label={`${count} of ${max} stars`}
      style={{ color: "var(--company-star-color)" }}
    >
      {times(max, (index) =>
        index < count ? <StarThemeIcon /> : <StarEmptyIcon />
      )}
    </div>
  )
}

const Locked = ({
  size,
  isProductModalLocked,
}: {
  size: StarSize
  isProductModalLocked: boolean | undefined
}) => {
  return (
    <>
      {isProductModalLocked ? (
        <div className={modifiers(s, "star", size)}>
          <StarEmptyIcon />
        </div>
      ) : (
        <div className={s.locked}>
          <LockedIcon />
        </div>
      )}
    </>
  )
}
