import { auth } from "auth"
import i18next from "i18next"
import { loadLocales } from "../i18n/loadLocales"
import { pickLocaleForCurrentCompany } from "../i18n/pickLocaleForCurrentCompany"
import { initializeI18nNext } from "./initializeI18nNext"

export const initializeI18n = async () => {
  initializeI18nNext()

  const locale = await pickLocaleForCurrentCompany()

  i18next.changeLanguage(locale)
  auth.updateConfig({ locale })
  document.documentElement.lang = locale

  loadLocales([locale])

  return locale
}
