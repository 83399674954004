import { ReactComponent as MagicStar } from "assets/icons/starMagic.svg"
import certificateBadgeUnlocked from "assets/illustrations/certificateBadgeUnlocked.svg"
import unlockedSunrise from "assets/illustrations/unlockedSunrise.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useOnEscape } from "hooks"
import { useDisableDocumentScroll } from "hooks/src/useDisableDocumentScroll"
import { times } from "lodash-es"
import { Button, Modal } from "materia"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { backgroundImageStyle } from "ui-tools"
import { useConfettiBannerStore } from "../ConfettiBanner/store"
import { type Data, query } from "./gql"
import s from "./styles.module.scss"

export const DiplomaUnlockModal = () => {
  const [isOpen, setIsOpen] = useState(true)
  const { isBlocked, progressChange } = useConfettiBannerStore()

  const { data, isPending } = useGraphqlQuery(
    query,
    { id: progressChange?.productId! },
    { enabled: progressChange !== undefined }
  )

  if (isPending || !data) return
  if (progressChange === undefined) return

  const { certified } = data.product.progress
  const hasCertificate = Boolean(data.product.diplomaConfiguration)

  const showModal = isOpen && certified && !isBlocked

  if (!showModal || !hasCertificate) return

  return <Content data={data} setIsOpen={setIsOpen} />
}

const Content = ({
  data,
  setIsOpen,
}: {
  data: Data
  setIsOpen: (value: boolean) => void
}) => {
  const { t } = useTranslation()

  const onClose = () => setIsOpen(false)

  useOnEscape(onClose)
  useDisableDocumentScroll()

  const { title, description } = data.product.diplomaConfiguration ?? {}

  return (
    <Modal onClose={onClose} overflowAuto={false} ariaLabel="New certification">
      <div className={s.diplomaUnlockModal}>
        <MagicStars />
        <div
          className={s.diplomaUnlockModal__cover}
          style={backgroundImageStyle(unlockedSunrise)}
        >
          <img
            src={certificateBadgeUnlocked}
            className={s.diplomaUnlockModal__thumbnail}
            alt=""
          />
        </div>
        <div className={s.diplomaUnlockModal__content}>
          <div className={s.diplomaUnlockModal__label}>
            {t("diploma.NEW_DIPLOMA")}
          </div>
          <h3 className={s.diplomaUnlockModal__title}>{title}</h3>
          <p className={s.diplomaUnlockModal__description}>{description}</p>
          <Button onClick={onClose} borderRadius={"32"}>
            {t("diploma.NEW_DIPLOMA_UNLOCKED_CONTINUE")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const MagicStars = () => {
  const NUMBER_OF_STARS = 10 // Manually adjusted in CSS file
  return times(NUMBER_OF_STARS, (index) => (
    <MagicStar className={s.diplomaUnlockModal__star} key={index} />
  ))
}
