import { create } from "zustand"
import {
  checkForProgressChange,
  type ProgressChange,
} from "./checkForProgressChange"
import { triggerConfetti } from "./triggerConfetti"

type Store = {
  isBlocked: boolean
  isVisible: boolean

  productId?: string
  progressChange?: ProgressChange

  setCurrentProductId: (productId: string) => void
  setIsBlocked: (isBlocked: boolean) => void
  updateCurrentProductProgress: () => void

  isReadyToDisplay: () => boolean
  showBanner: () => void
}

let timeout: number

export const useConfettiBannerStore = create<Store>((set, get) => ({
  isBlocked: false,
  isVisible: false,

  setCurrentProductId: async (productId) => {
    set({ productId })

    await checkForProgressChange(productId)
  },

  setIsBlocked: (isBlocked) => {
    set({ isBlocked })

    const { isReadyToDisplay, showBanner } = get()
    if (isReadyToDisplay()) showBanner()
  },

  updateCurrentProductProgress: async () => {
    const { productId, isReadyToDisplay, showBanner } = get()

    if (productId === undefined) return

    const progressChange = await checkForProgressChange(productId)

    const isStaleData = productId !== get().productId
    if (isStaleData) return

    set({ progressChange })

    if (isReadyToDisplay()) showBanner()
  },

  isReadyToDisplay: () => {
    const { isBlocked, progressChange, productId } = get()
    return (
      !isBlocked &&
      progressChange !== undefined &&
      progressChange.productId === productId
    )
  },

  showBanner: () => {
    set({ isVisible: true })

    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => set({ isVisible: false }), 5_000)

    triggerConfetti()
  },
}))
