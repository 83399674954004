import { ProductModal } from ".."
import s from "./styles.module.scss"

export const ProductModalMainLoading = () => {
  return (
    <ProductModal.Main>
      <div className={s.skeleton}>
        <div className={s.skeleton__top} />
        <div className={s.skeleton__bottom}>
          <div className={s.skeleton__title} />
          <div className={s.skeleton__description} />
          <div className={s.skeleton__description} />
          <div className={s.skeleton__button} />
        </div>
      </div>
    </ProductModal.Main>
  )
}
