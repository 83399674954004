import { Card } from "materia"
import s from "./styles.module.scss"

export const FeaturedProductsLoading = () => {
  return (
    <Card borderRadius="24">
      <div className={s.skeleton}>
        <div className={s.skeleton__featured} />
        <div className={s.skeleton__title} />
      </div>
    </Card>
  )
}
