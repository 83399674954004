import s from "./styles.module.scss"

export const EventWidgetMobileLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__event}>
        <div className={s.skeleton__date} />
        <div className={s.skeleton__infoWrapper}>
          <div className={s.skeleton__info} />
          <div className={s.skeleton__eventTitle} />
        </div>
      </div>
      <div className={s.skeleton__arrow} />
    </div>
  )
}
