const hexToRgb = (hex: string) => {
  hex = hex.replace("#", "")

  // Convert 3-digit hex to 6-digit hex
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("")
  }

  // Convert hex to RGB components
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return { r, g, b }
}

const getLuminance = (hex: string) => {
  const { r, g, b } = hexToRgb(hex)

  // Normalize RGB values to 0-1 range
  const normR = r / 255
  const normG = g / 255
  const normB = b / 255

  // Apply sRGB gamma correction
  const lumR =
    normR <= 0.03928 ? normR / 12.92 : Math.pow((normR + 0.055) / 1.055, 2.4)
  const lumG =
    normG <= 0.03928 ? normG / 12.92 : Math.pow((normG + 0.055) / 1.055, 2.4)
  const lumB =
    normB <= 0.03928 ? normB / 12.92 : Math.pow((normB + 0.055) / 1.055, 2.4)

  // Return relative luminance
  return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB
}

const getContrastRatio = (foregroundHex: string, backgroundHex: string) => {
  const lum1 = getLuminance(foregroundHex)
  const lum2 = getLuminance(backgroundHex)

  // Ensure lum1 is the lighter color
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)

  // Contrast ratio formula
  return (brightest + 0.05) / (darkest + 0.05)
}

/**
 * Method to check if the contrast between two colors is accessible within WCAG AA standard (4.5:1)
 */
export const isColorContrastAccessible = (
  textColor: string,
  backgroundColor: string
) => {
  const contrastRatio = getContrastRatio(textColor, backgroundColor)

  return contrastRatio >= 4.5
}
