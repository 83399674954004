import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import productInProgress from "assets/icons/productInProgress.svg"
import { Card } from "materia"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { TEXT_FALLBACK, modifiers } from "ui-tools"
import { FiestaImage, FiestaLink } from "utility-components"
import {
  getIsLeaderboardProduct,
  getIsWebGamesProduct,
  getProductLink,
} from "../../dataUtilities/productDataUtilities"
import { Badge } from "../Badge"
import { Deadline } from "../Deadline"
import { Stars } from "../Stars"
import { type Data } from "./gql"
import { ProductCardActivity } from "./ProductCardActivity"
import { ProductCardEvent } from "./ProductCardEvent"
import { ProductCardLeaderboardsDetails } from "./ProductCardLeaderboardsDetails"
import s from "./styles.module.scss"

export const ProductCard = ({ data }: { data: Data }) => {
  if (data.productType === "activity") {
    return <ProductCardActivity data={data} />
  }

  if (data.productType === "meet") {
    return <ProductCardEvent data={data} />
  }

  return <DefaultCardLoader data={data} />
}

const DefaultCardLoader = ({ data }: { data: Data }) => {
  const { id, productType, progress, isLocked, hasPlayedXapi } = data

  const isLeaderboardProduct = getIsLeaderboardProduct(productType)
  const inProgress = hasPlayedXapi && !progress.certified

  return (
    <li>
      <Card>
        <FiestaLink
          to={getProductLink(id, productType)}
          className={modifiers(s, "productCard", { isLocked })}
          aria-haspopup="dialog"
          replace={true}
        >
          {data && <CertifiedOrDeadline data={data} />}
          {progress.mandatory && <Mandatory />}
          {inProgress && <InProgress />}
          <Image data={data} />
          <Bottom>
            {isLeaderboardProduct ? (
              <ProductCardLeaderboardsDetails data={data} />
            ) : (
              <ProductType data={data} />
            )}
            <Title data={data} />
            <Stars
              count={progress.starsCount}
              max={progress.starsMax}
              isLocked={isLocked}
            />
          </Bottom>
        </FiestaLink>
      </Card>
    </li>
  )
}

const Mandatory = () => {
  const { t } = useTranslation()
  return (
    <div className={s.mandatory}>
      <Badge title={t("user.MANDATORY")} size="small" />
    </div>
  )
}

const CertifiedOrDeadline = ({ data }: { data: Data }) => {
  const { progress, deadlineOccurrence } = data
  const status = deadlineOccurrence?.status

  // TODO: Fix the styling based on the wrapper-class .deadline that makes the deadlines float around
  if (status === "due")
    return (
      <div className={s.deadline}>
        <Deadline variant="dueDate" size="small" />
      </div>
    )
  if (status === "overdue")
    return (
      <div className={s.deadline}>
        <Deadline variant="overdue" size="small" />
      </div>
    )
  if (progress.certified) return <Certified />
}

const Certified = () => {
  return (
    <img className={s.certified} src={checkmarkCircleGreen} alt="Completed" />
  )
}

const InProgress = () => {
  return <img className={s.inProgress} src={productInProgress} alt="" />
}

const Image = ({ data }: { data: Data }) => {
  const { image } = data
  return (
    <FiestaImage
      className={s.image}
      src={image}
      alt=""
      loading="lazy"
      sizes="(width = small) 100px, (width = medium) 33vw, 200px"
    />
  )
}

const Bottom = ({ children }: { children: ReactNode }) => {
  return <div className={s.bottom}>{children}</div>
}

const ProductType = ({ data }: { data: Data }) => {
  const { productType, progress, hasPlayedXapi } = data
  const { t } = useTranslation()

  const inProgress = hasPlayedXapi && !progress.certified
  const isWebGamesProduct = getIsWebGamesProduct(productType)

  if (isWebGamesProduct) {
    return (
      <Badge
        title={t(`product.PRODUCT_TYPES.${productType}`)}
        type="webGames"
        size="small"
      />
    )
  } else {
    return (
      <div className={s.productType}>
        {t(`product.PRODUCT_TYPES.${productType}`)}
        {inProgress && ` (${t("product.PRODUCT_IN_PROGRESS")})`}
      </div>
    )
  }
}

const Title = ({ data }: { data: Data }) => {
  const { title } = data
  return <h3 className={s.title}>{title || TEXT_FALLBACK}</h3>
}
