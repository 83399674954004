import type {
  InvalidateOptions,
  InvalidateQueryFilters,
} from "@tanstack/react-query"
import { queryClient } from "./queryClient"
import type { FnBase, FnParams } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = InvalidateOptions
type Filters = InvalidateQueryFilters
type Return = Promise<void>
type Arguments<Fn extends FnBase> = Partial<FnParams<Fn>>

export type InvalidateQueries<Fn extends FnBase> = (
  args?: Arguments<Fn>,
  filters?: Filters,
  options?: Options
) => Return

export const getInvalidateQueries = (
  path: string[]
): InvalidateQueries<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const key = [...path, ...args]
    return queryClient.invalidateQueries({
      ...rest,
      queryKey: key,
    })
  }
}
