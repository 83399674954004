import { useQueryParam, useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { HiddenHeader } from "../../components/HiddenHeader"
import type { DeadlineStatus } from "../../graphql"
import { usePageTitle } from "../../tools/usePageTitle"
import { FilterView } from "../FilterView"
import { FeaturedProducts } from "./FeaturedProducts"
import { JourneyTeaser } from "./JourneyTeaser"
import { MobileWidgets } from "./MobileWidgets"
import { ProductCategories } from "./ProductCategories"
import s from "./styles.module.scss"

export const ForYouView = () => {
  useScrollToTopOnMount()
  const { t } = useTranslation()
  usePageTitle(t("pageTitle.FOR_YOU"))

  const [mandatoryQuery] = useQueryParam("mandatory")
  const [incompleteQuery] = useQueryParam("incomplete")
  const [deadlineStatus] = useQueryParam<DeadlineStatus>("deadline_status")
  const isMandatory = mandatoryQuery === "true" ? true : undefined
  const isIncomplete = incompleteQuery === "true" ? true : undefined

  if (isMandatory || isIncomplete) {
    return (
      <FilterView
        deadlineStatus={deadlineStatus}
        isIncomplete={isIncomplete}
        isMandatory={isMandatory}
      />
    )
  }

  return (
    <div className={s.view}>
      <HiddenHeader title={t("navigation.FOR_YOU")} tag="h1" />
      <FeaturedProducts />
      <JourneyTeaser />
      <MobileWidgets />
      <ProductCategories />
    </div>
  )
}
