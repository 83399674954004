import { auth } from "auth"
import { APP_TYPE } from "../vuplex/constants"

export const addPlayLinkParams = async ({
  link,
  useTransferToken,
}: {
  link: string | null
  useTransferToken: boolean
}) => {
  if (!link) return

  // Skills is responsible for login and don't need access info from portal
  if (APP_TYPE === "skills") return link

  return auth.addAccessParams(link, {
    useTransferToken,
    accessTokenName: "accessToken",
    includeCompany: false,
  })
}
