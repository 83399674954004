import { withQueryParams } from "utils"
import { accessTokenToTransferKey } from "./api/transferKey"
import { getActiveSession } from "./getActiveSession"
import type { Session } from "./types/Session"

type Options = {
  useTransferToken?: boolean
  accessTokenName?: string
  includeCompany?: boolean
}

export const addAccessParams = async (url: string, options: Options = {}) => {
  const session = await getActiveSession()

  return withQueryParams(url, {
    ...(await getCompanyParams(options, session)),
    ...(await getAccessParams(options, session)),
  })
}

const getCompanyParams = (
  { includeCompany = true }: Options,
  session: Session
) => {
  if (includeCompany)
    return {
      subdomain: session.subdomain,
      company_name_key: session.companyNameKey,
    }
  else {
    return {}
  }
}

const getAccessParams = async (options: Options, session: Session) => {
  const { useTransferToken = true, accessTokenName = "access_token" } = options
  if (useTransferToken) {
    return { transfer_key: await accessTokenToTransferKey(session.accessToken) }
  } else {
    return { [accessTokenName]: session.accessToken }
  }
}
