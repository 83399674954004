import { useInfiniteGraphqlQuery } from "graphql-mock"
import { last } from "lodash-es"
import { useTranslation } from "react-i18next"
import { ErrorPopup } from "utility-components"
import { ProductBundleCard } from "../../../components/ProductBundleCard"
import { ProductCard } from "../../../components/ProductCard"
import { ProductCardsLoading } from "../../../components/ProductCard/loading"
import { ProductCardShell } from "../../../components/ProductCard/shell"
import { ProductList } from "../../../components/ProductList"
import { query } from "./gql"
import { ProductCategoryProductsLoading } from "./loading"
import s from "./styles.module.scss"

const ITEMS_PER_PAGE = 6

export const ProductCategoryProducts = ({ id }: { id: string }) => {
  const { t } = useTranslation()

  const { data, error, isPending, isFetchingNextPage, fetchNextPage } =
    useInfiniteGraphqlQuery(
      query,
      { id, limit: ITEMS_PER_PAGE },
      {
        getNextPage: (data) =>
          data.productCategory.productCategorizations.pageInfo.nextPage,
      }
    )

  if (isPending)
    return <ProductCategoryProductsLoading count={ITEMS_PER_PAGE} />
  if (error) return <ErrorPopup error={error} />

  const pageInfo = last(data.pages)?.productCategory.productCategorizations
    .pageInfo
  if (!pageInfo) throw new Error("There must be at least one page")

  const items = data.pages.flatMap(
    (page) => page.productCategory.productCategorizations.nodes
  )

  const slicedItems = pageInfo.hasNextPage ? items.slice(0, -1) : items

  const showMoreCount = pageInfo.hasNextPage
    ? pageInfo.remainingItemCount + 1
    : pageInfo.remainingItemCount

  const skeletonCount = pageInfo.hasNextPage
    ? pageInfo.nextPageItemCount + 1
    : pageInfo.nextPageItemCount

  const showMoreText = `${t("product.SHOW_MORE")} (${showMoreCount})`

  return (
    <ProductList>
      {slicedItems.map((item, index) => {
        if (item.product) {
          return <ProductCard key={index} data={item.product} />
        } else if (item.productBundle) {
          return <ProductBundleCard key={index} data={item.productBundle} />
        } else {
          throw new Error("There must be a product or a bundle")
        }
      })}
      {isFetchingNextPage && <ProductCardsLoading count={skeletonCount} />}
      {pageInfo.hasNextPage && !isFetchingNextPage && (
        <ShowMore onClick={fetchNextPage} text={showMoreText} />
      )}
    </ProductList>
  )
}

const ShowMore = ({
  text,
  onClick,
}: {
  text: string
  onClick?: () => void
}) => (
  <ProductCardShell>
    <button className={s.showMore} onClick={onClick}>
      <div className={s.showMore__text}>{text}</div>
    </button>
  </ProductCardShell>
)
