import { times } from "lodash-es"
import { ProductCardShell } from "./shell"
import s from "./styles.module.scss"

export const ProductCardLoading = () => {
  return (
    <ProductCardShell>
      <div className={s.skeleton}>
        <div className={s.skeleton__image} />
        <div className={s.skeleton__bottom}>
          <div className={s.skeleton__bottomType} />
          <div className={s.skeleton__bottomTitle} />
        </div>
      </div>
    </ProductCardShell>
  )
}

export const ProductCardsLoading = ({ count }: { count: number }) => {
  return times(count, (index) => <ProductCardLoading key={index} />)
}
