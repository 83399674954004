import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { LOREM_IPSUM } from "ui-tools"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query NewsWidget {
    newsPosts(page: 1, limit: 3) {
      nodes {
        id
        image
        headline
        body
      }
    }
  }
`)

export const mockNewsWidgetData = mockData<Data>({
  newsPosts: {
    nodes: times(3, (index) => ({
      id: index.toString(),
      headline: `News item`,
      body: LOREM_IPSUM,
      image: productImage,
    })),
  },
})

registerMockData(query, mockNewsWidgetData())
