import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import featuredArrowIcon from "assets/icons/featuredArrowIcon.svg"
import { formatNumber } from "format"
import { useGraphqlQuery } from "graphql-mock"
import { times } from "lodash-es"
import { Card, ErrorMessage } from "materia"
import { useState, type PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaImage, FiestaLink } from "utility-components"
import { Badge } from "../../../components/Badge"
import { Deadline } from "../../../components/Deadline"
import { HiddenHeader } from "../../../components/HiddenHeader"
import { Stars } from "../../../components/Stars"
import {
  getIsLeaderboardProduct,
  getProductLink,
} from "../../../dataUtilities/productDataUtilities"
import { query, type Product, type ProductCategory } from "./gql"
import { FeaturedProductsLoading } from "./loading"
import s from "./styles.module.scss"

export const FeaturedProducts = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <FeaturedProductsLoading />
  if (error) return <FeaturedProductsError />

  const featured = data.productCategories.nodes[0]
  if (!featured) return

  return <Products featured={featured} />
}

export const FeaturedProductsError = () => {
  const { t } = useTranslation()
  return (
    <Card borderRadius="24">
      <div className={s.featured} data-chromatic="ignore">
        <div className={s.featured__products}>
          <ErrorMessage
            title={t("error.GENERIC")}
            message={t("error.FEATURED_FETCH_ERROR")}
          />
        </div>
      </div>
    </Card>
  )
}

const Products = ({ featured }: { featured: ProductCategory }) => {
  const { t } = useTranslation()
  const products = featured.products.nodes
  const { numberOfProducts, index, paginateNext, paginateBack } =
    usePaginatedProducts(products.length)

  const xPosition = `${-index * 100}%`
  const transform = `translateX(${xPosition})`
  const showArrows = numberOfProducts > 1

  return (
    /*
    Using chromatic ignore since chromatic cannot handle featured products currently.
    TODO: Figure out why
  */
    <Card borderRadius="24" transparent>
      <HiddenHeader title={t("product.FEATURED_MAINTITLE")} tag="h2" />
      <div className={s.featured} data-chromatic="ignore">
        <div className={s.featured__products} style={{ transform }}>
          {products.map((product) => (
            <FeaturedProduct key={product.id} product={product} />
          ))}
        </div>
        {showArrows && <Arrow onClick={paginateBack} direction="left" />}
        {showArrows && <Arrow onClick={paginateNext} direction="right" />}
        <Progress current={index} length={numberOfProducts} />
      </div>
    </Card>
  )
}

const FeaturedProduct = ({ product }: { product: Product }) => {
  const { productType, title, progress } = product
  const isLeaderboardProduct = getIsLeaderboardProduct(productType)

  const stars = progress.starsCount
  const starsMax = progress.starsMax

  return (
    <FiestaLink
      className={s.product}
      to={getProductLink(product.id, productType)}
    >
      <Image product={product} />
      <Badges>
        <CertifiedOrDeadline data={product} />
        {progress.mandatory && <Mandatory />}
      </Badges>
      <div className={s.product__textContainer}>
        <Gradient>
          <h3 className={s.product__title}>{title}</h3>
          <div className={s.product__scoreAndStars}>
            {isLeaderboardProduct && <Score product={product} />}
            <div
              className={s.stars}
              aria-label={`${stars} of ${starsMax} stars`}
            >
              <Stars count={stars} max={starsMax} />
            </div>
          </div>
        </Gradient>
      </div>
    </FiestaLink>
  )
}

type ArrowProps = {
  direction: "left" | "right"
  onClick: () => void
}
const Arrow = ({ direction, onClick }: ArrowProps) => (
  <button
    className={modifiers(s, "arrow", direction)}
    onClick={onClick}
    title={`Navigate ${direction}`}
  >
    <img src={featuredArrowIcon} alt="" />
  </button>
)

type ProgressProps = {
  current: number
  length: number
}
const Progress = ({ current, length }: ProgressProps) => {
  const progressItems = times(length, (index) => (
    <ProgressItem isActive={index === current} key={index} />
  ))

  return <div className={s.progress}>{progressItems}</div>
}

type ProgressItemProps = {
  isActive: boolean
}
const ProgressItem = ({ isActive }: ProgressItemProps) => (
  <div className={modifiers(s, "progressItem", { isActive })} />
)

const Image = ({ product }: { product: Product }) => {
  const { image } = product
  return (
    <FiestaImage
      className={s.image}
      src={image}
      alt=""
      sizes="(width = big) 650px, 100vw"
    />
  )
}

const Badges = ({ children }: PropsWithChildren) => (
  <div className={s.product__badges}>{children}</div>
)

const CertifiedOrDeadline = ({ data }: { data: Product }) => {
  const { progress, deadlineOccurrence } = data
  const status = deadlineOccurrence?.status

  if (status === "due") return <Deadline variant="dueDate" size="small" />
  if (status === "overdue") return <Deadline variant="overdue" size="small" />
  if (progress.certified) return <Certified />
  return <div />
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="Completed" />
)

const Mandatory = () => {
  const { t } = useTranslation()
  return <Badge title={t("user.MANDATORY")} />
}

const Gradient = ({ children }: PropsWithChildren) => (
  <div className={s.product__gradient}>{children}</div>
)

const Score = ({ product }: { product: Product }) => {
  const { i18n, t } = useTranslation()
  const { progress } = product
  const score = formatNumber(progress.highScore, i18n.language)
  const noPoints = progress.highScore === 0
  const badgeType = noPoints ? "scoreNoPoints" : "score"

  return <Badge title={`${score} ${t("product.SCORE")}`} type={badgeType} />
}

const usePaginatedProducts = (numberOfProducts: number) => {
  const [index, setIndex] = useState(0)

  const paginateBack = () => {
    if (index === 0) {
      setIndex(numberOfProducts - 1)
    } else {
      setIndex(index - 1)
    }
  }

  const paginateNext = () => {
    if (index === numberOfProducts - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  return { index, numberOfProducts, paginateBack, paginateNext }
}
