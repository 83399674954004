import { ReactComponent as DiplomaLockedIcon } from "assets/icons/diplomaLockedSmall.svg"
import { ReactComponent as DiplomaUnlockedIcon } from "assets/icons/diplomaUnlockedSmall.svg"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink } from "utility-components"
import { trackEvent } from "../../../../tools/trackEvent"
import { Deadline } from "../../../Deadline"
import type { Data } from "../gql"
import s from "./styles.module.scss"

/**
 * Shows a diploma status of a product as a button which can be
 * clicked to preview said diploma. Will show a deadline date
 * and status if it exists.
 * Should be used for products that have diplomas.
 * For products with only deadline, use `DeadlineStatus` component.
 */
export const DiplomaStatus = ({ product }: { product: Data }) => {
  const deadlineStatus = product?.deadlineOccurrence?.status
  const date = product?.deadlineOccurrence?.deadlineDate
  const diplomaTitle = product?.diplomaConfiguration?.title

  const getDiplomaStatus = () => {
    // Diploma and deadline are independent of each other.
    // First we check if a product has a due/overdue deadline.
    switch (deadlineStatus) {
      case "due":
        return (
          <Deadline
            variant="dueDate"
            size="medium"
            deadline={date}
            hasDiploma
          />
        )
      case "overdue":
        return (
          <Deadline
            variant="overdue"
            size="medium"
            deadline={date}
            hasDiploma
          />
        )
    }

    // If there is no due/overdue deadline, then we don't care about
    // deadlines anymore and just want to know if user has a valid
    // certification or not.
    const isUserCertifiedInProduct =
      deadlineStatus === "certified" ||
      deadlineStatus === "certified_after_deadline" ||
      Boolean(product?.progress.certified)

    return (
      <DiplomaStatusButton
        isCertified={isUserCertifiedInProduct}
        diplomaTitle={diplomaTitle}
      />
    )
  }

  return (
    <FiestaLink
      className={s.statusWrapper}
      to="diploma"
      onClick={() => trackEvent("Diploma button click")}
    >
      {getDiplomaStatus()}
    </FiestaLink>
  )
}

const DiplomaStatusButton = ({
  isCertified,
  diplomaTitle,
}: {
  isCertified: Boolean
  diplomaTitle?: string | null
}) => {
  const { t } = useTranslation()
  const Icon = isCertified ? DiplomaUnlockedIcon : DiplomaLockedIcon

  return (
    <div className={modifiers(s, "certifiedStatus", { isCertified })}>
      <Icon
        aria-label={isCertified ? "Diploma unlocked" : "Diploma not unlocked"}
        role="img"
      />
      <div className={s.certifiedStatus__caption}>
        {diplomaTitle ?? t("product.CERTIFIED")}
      </div>
    </div>
  )
}
