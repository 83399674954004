import confusedClouds from "assets/illustrations/confusedClouds.svg"
import forestLine from "assets/illustrations/forestLine.svg"
import type { ReactNode } from "react"
import s from "./styles.module.scss"

type ErrorMessageProps = {
  title: ReactNode
  message?: ReactNode
}
export const ErrorMessage = ({ title, message }: ErrorMessageProps) => {
  return (
    <div className={s.error} aria-live="polite">
      <div className={s.illustration}>
        <img src={confusedClouds} className={s.illustration__top} alt="" />
        <img src={forestLine} className={s.illustration__bottom} alt="" />
      </div>

      <div className={s.content}>
        <div className={s.content__title}>{title}</div>
        {message && <div className={s.content__message}>{message}</div>}
      </div>
    </div>
  )
}
