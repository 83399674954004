import { memoize } from "lodash-es"
import { graphql, graphqlRequest } from "../graphql"

const query = graphql(`
  query GetCompanyCached {
    currentCompany {
      portalFeatureFlags
      portalSupportedLocales
    }
  }
`)

export const getCompanyCached = memoize(async () => {
  const data = await graphqlRequest({ query, variables: {} })
  return data.currentCompany
})
