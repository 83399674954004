import { useLocation, useNavigate } from "@tanstack/react-location"

const initialHistoryLength = window.history.length

export const useNavigateBack = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return () => {
    const isSafeToGoBack = window.history.length > initialHistoryLength

    if (isSafeToGoBack) {
      location.history.back()
    } else {
      navigate({ to: "/" })
    }
  }
}
