import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["product"]

export const query = graphql(`
  query DiplomaUnlockModal($id: ID!) {
    product(id: $id) {
      progress {
        certified
      }
      diplomaConfiguration {
        id
        title
        description
      }
    }
  }
`)

export const mockDiplomaUnlockModalData = mockData<Data>({
  product: {
    progress: {
      certified: true,
    },
    diplomaConfiguration: {
      id: "1",
      title: "Diploma title",
      description: "Diploma description",
    },
  },
})

registerMockData(query, mockDiplomaUnlockModalData())
