import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query ViewerModal($productId: ID!, $platform: Platform) {
    currentCompany {
      companyFeatureFlags
    }
    product(id: $productId) {
      id
      title
      productType
      contentLink
      useTransferToken
      noSkip

      localeContentLinks {
        nodes {
          link
          locale
        }
      }
      productUploads {
        nodes {
          locale
          url
        }
      }

      progress {
        certified
      }

      playLinks(platform: $platform) {
        webglLink
        appLink
        launcherLink
      }
    }
  }
`)

export const mockViewerModalData = mockData<Data>({
  currentCompany: {
    companyFeatureFlags: ["enable_no_skip_videos"],
  },
  product: {
    id: "1",
    title: "Product #1",
    productType: "pdf",
    contentLink: productImage,
    useTransferToken: false,
    noSkip: false,

    localeContentLinks: {
      nodes: [
        {
          link: productImage,
          locale: "en-US",
        },
      ],
    },

    productUploads: {
      nodes: [
        {
          locale: "en-US",
          url: productImage,
        },
      ],
    },

    progress: {
      certified: true,
    },

    playLinks: {
      webglLink: productImage,
      appLink: productImage,
      launcherLink: productImage,
    },
  },
})

registerMockData(query, mockViewerModalData())
