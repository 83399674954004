import avatarDemoIcon from "assets/icons/avatarDemoIcon.svg"
import attensiLogoBlue from "assets/logos/attensiLogoBlue.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"
import { mockNavigationData, navigationFragment } from "../Navigation/gql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query Header {
      currentUser {
        avatar
        displayName
        avatarTitle
      }
      currentCompany {
        logoUrl
      }
      navigationCompany: currentCompany {
        ...Navigation
      }
    }
  `,
  [navigationFragment]
)

export const mockHeaderData = mockData<Data>({
  currentUser: {
    avatar: avatarDemoIcon,
    avatarTitle: "Wow such nice title",
    displayName: "User Name",
  },
  currentCompany: {
    logoUrl: attensiLogoBlue,
  },
  navigationCompany: mockNavigationData(),
})

registerMockData(query, mockHeaderData())
