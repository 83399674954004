import { Card } from "materia"
import s from "./styles.module.scss"

export const JourneyTeaserLoading = () => {
  return (
    <Card borderRadius="24">
      <div className={s.skeleton}>
        <div className={s.skeleton__title} />
        <div className={s.skeleton__status} />
      </div>
    </Card>
  )
}
