import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../../graphql"
import { journeyFragment, mockJourneyData } from "../Journey/gql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query Journeys($limit: Int!, $page: Int) {
      productCategories(isJourney: true, limit: $limit, page: $page) {
        pageInfo {
          nextPage
          nextPageItemCount
        }
        nodes {
          ...Journey
        }
      }
    }
  `,
  [journeyFragment]
)

export const mockJourneysData = mockData<Data>({
  productCategories: {
    pageInfo: {
      nextPage: null,
      nextPageItemCount: 0,
    },
    nodes: times(3, () => mockJourneyData()),
  },
})

registerMockData(query, mockJourneysData())
