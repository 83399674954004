import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import {
  mockProductBundleCardData,
  productBundleFragment,
} from "../../../components/ProductBundleCard/gql"
import {
  mockProductCardData,
  productCardFragment,
} from "../../../components/ProductCard/gql"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query ProductCategoryProducts($id: ID!, $limit: Int!, $page: Int) {
      productCategory(id: $id) {
        productCategorizations(limit: $limit, page: $page) {
          pageInfo {
            hasNextPage
            nextPageItemCount
            remainingItemCount
            nextPage
          }
          nodes {
            product {
              ...ProductCard
            }
            productBundle {
              ...ProductBundleCard
            }
          }
        }
      }
    }
  `,
  [productCardFragment, productBundleFragment]
)

export const mockProductCategoryProductsData = mockData<Data>({
  productCategory: {
    productCategorizations: {
      pageInfo: {
        hasNextPage: false,
        nextPage: null,
        nextPageItemCount: 0,
        remainingItemCount: 0,
      },
      nodes: [
        {
          product: mockProductCardData(),
          productBundle: null,
        },
        {
          product: mockProductCardData({ productType: "meet", isLocked: true }),
          productBundle: null,
        },
        {
          product: null,
          productBundle: mockProductBundleCardData(),
        },
        {
          product: mockProductCardData({ productType: "activity" }),
          productBundle: null,
        },
        {
          product: mockProductCardData({ productType: "meet" }),
          productBundle: null,
        },
        {
          product: mockProductCardData({ isLocked: true }),
          productBundle: null,
        },
      ],
    },
  },
})

registerMockData(query, mockProductCategoryProductsData())
