import { Navigate } from "@tanstack/react-location"
import { useGraphqlQuery } from "graphql-mock"
import { ErrorPopup } from "utility-components"
import { ForYouViewLoading } from "../ForYouView/loading"
import { query, type Data } from "./gql"

export const FrontView = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <ForYouViewLoading />
  if (error) return <ErrorPopup error={error} />

  return <Navigate replace to={getLandingPage(data)} />
}

const getLandingPage = (data: Data) => {
  const landingPage = data.currentCompany.portalLandingPage

  switch (landingPage) {
    case "for_you":
      return "for-you"
    default:
      return landingPage
  }
}
