import type { Role } from "../graphql"

export const getHasAdminAccess = (roles: Role[]) => {
  const rolesWithAccess = new Set<Role>([
    "admin",
    "superadmin",
    "manager",
    "superuser",
    "supervisor",
  ])

  return roles.some((role) => rolesWithAccess.has(role))
}
