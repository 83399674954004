import { ReactComponent as LikeIcon } from "assets/icons/likeIcon.svg"
import starBackground from "assets/icons/likeIconStarBackground.svg"
import { useGraphqlMutation } from "graphql-mock"
import { useOptimisticUpdate } from "hooks"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { mutation } from "./gql"
import s from "./styles.module.scss"

export const NewsPostLikeButton = ({
  newsPostId,
  isLiked: isLikedLive,
}: {
  newsPostId: string
  isLiked: boolean
}) => {
  const { t } = useTranslation()
  const { mutate } = useGraphqlMutation(mutation)

  const [isLiked, updateIsLiked, isUpdated] = useOptimisticUpdate({
    liveValue: isLikedLive,
    updateFn: (isLiked) => mutate({ input: { newsPostId, isLiked } }),
  })

  return (
    <button
      className={modifiers(s, "likeButton", { isLiked, isUpdated })}
      onClick={() => updateIsLiked(!isLiked)}
    >
      <div className={s.likeButton__iconContainer}>
        <img className={s.likeButton__background} src={starBackground} alt="" />
        <LikeIcon className={s.likeButton__icon} />
      </div>
      <span>{isLiked ? t("news.LIKED") : t("news.LIKE")}</span>
    </button>
  )
}
