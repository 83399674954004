import journeyIllustration from "assets/illustrations/journeyIllustration.svg"
import { useInfiniteGraphqlQuery } from "graphql-mock"
import { last } from "lodash-es"
import { useTranslation } from "react-i18next"
import { ErrorPopup, WhenVisible } from "utility-components"
import { Journey } from "../Journey"
import { query } from "./gql"
import { JourneysLoading } from "./loading"
import s from "./styles.module.scss"

export const ITEMS_PER_PAGE = 3

export const Journeys = () => {
  const { data, error, isPending, isFetchingNextPage, fetchNextPage } =
    useInfiniteGraphqlQuery(
      query,
      { limit: ITEMS_PER_PAGE },
      { getNextPage: (data) => data.productCategories.pageInfo.nextPage }
    )

  if (isPending) return <JourneysLoading count={ITEMS_PER_PAGE} />
  if (error) return <ErrorPopup error={error} />

  const pageInfo = last(data.pages)?.productCategories.pageInfo
  if (!pageInfo) throw new Error("There must be at least one page")

  const journeys = data.pages.flatMap((page) => page.productCategories.nodes)
  const isEmpty = journeys.length === 0

  if (isEmpty) return <Empty />

  return (
    <>
      {journeys.map((journey, index) => (
        <Journey key={index} data={journey} />
      ))}
      {isFetchingNextPage && (
        <JourneysLoading count={pageInfo.nextPageItemCount} />
      )}

      <WhenVisible
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        key={journeys.length}
      />
    </>
  )
}

const Empty = () => {
  const { t } = useTranslation()

  return (
    <div className={s.empty}>
      <img src={journeyIllustration} className={s.empty__illustration} alt="" />
      <div className={s.empty__text}>{t("notFound.NOTFOUND_JOURNEY")}</div>
    </div>
  )
}
