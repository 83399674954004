import closeIcon from "assets/icons/closeIcon.svg"
import { Modal } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const ProductModalDownload = ({
  onToggle,
}: {
  onToggle: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Modal onClose={onToggle} ariaLabel="Play button not working">
      <div className={s.productModalDownload}>
        <div className={s.productModalDownload__title}>
          {t("helpModal.TITLE")}
        </div>
        <div className={s.productModalDownload__description}>
          {t("helpModal.DESCRIPTION")}
        </div>

        <button className={s.closeButton} onClick={onToggle}>
          <img className={s.closeButton__icon} src={closeIcon} alt="Close" />
        </button>
      </div>
    </Modal>
  )
}
