import { ProductCardsLoading } from "../ProductCard/loading"
import { ProductList } from "../ProductList"

export const FilteredProductsLoading = ({ count }: { count: number }) => {
  return (
    <ProductList>
      <ProductCardsLoading count={count} />
    </ProductList>
  )
}
