import arrow from "assets/icons/arrowRight.svg"
import { formatDate } from "format"
import { useGraphqlQuery } from "graphql-mock"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup, FiestaImage, FiestaLink } from "utility-components"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import { query, type Course, type Product } from "./gql"
import { EventWidgetLoading } from "./loading"
import s from "./styles.module.scss"

export const EventWidget = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})
  const { t } = useTranslation()

  if (isPending) return <EventWidgetLoading />
  if (error) return <ErrorPopup error={error} />

  const products = data.products.nodes
  const isEmpty = products.length === 0

  const isDisabled = data.currentCompany.portalFeatureFlags.includes(
    "disable_event_widget_page"
  )

  if (isDisabled || isEmpty) return

  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        <div className={s.widget__header}>
          <div className={s.widget__text}>{t("event.UPCOMING_EVENTS")}</div>
          <FiestaLink
            to="/events"
            className={s.widget__arrowContainer}
            ariaLabel="Open event page"
          >
            <FiestaImage
              className={s.widget__arrow}
              src={arrow}
              alt="Arrow"
              sizes="60px"
            />
          </FiestaLink>
        </div>
        <div className={s.widget__courseWrapper}>
          {products.map((product, index) => (
            <Item product={product} key={index} />
          ))}
        </div>
      </div>
    </Card>
  )
}

const Item = ({ product }: { product: Product }) => {
  const course = product.course
  if (course === null) return

  const isEmpty = !course
  const { isAttending } = course

  return (
    <FiestaLink
      className={modifiers(s, "item", { isAttending, isEmpty })}
      to={getProductLink(product.id, "meet")}
      replace={true}
    >
      <div className={s.item__date}>
        <DateInfo course={course} />
        <div className={modifiers(s, "item__title", { isAttending })}>
          {product.title}
        </div>
      </div>
      <Info course={course} />
    </FiestaLink>
  )
}

const DateInfo = ({ course }: { course: Course }) => {
  const { i18n } = useTranslation()

  return (
    <div className={s.date}>
      <div className={s.date__month}>
        {formatCourseMonth(course, i18n.language)}
      </div>
      <div className={s.date__day}>{formatCourseDay(course)}</div>
    </div>
  )
}

const Info = ({ course }: { course: Course }) => {
  const { t } = useTranslation()
  const { isAttending } = course

  return (
    <div className={modifiers(s, "info", { isAttending })}>
      {isAttending ? t("event.ATTENDING") : t("event.ATTEND")}
    </div>
  )
}

const formatCourseDay = (course: Course) => {
  const startDatetime = course.startDatetime
  const date = new Date(startDatetime)

  return date.getDate().toString()
}

const formatCourseMonth = (course: Course, locale: string) => {
  const startDatetime = course.startDatetime

  return formatDate(startDatetime, locale, {
    month: "short",
  })
}
