import {
  createContext,
  useContext,
  useMemo,
  useRef,
  type PropsWithChildren,
} from "react"

type ContextType = ReturnType<typeof useValue>

const TopLevelContext = createContext<ContextType | null>(null)

export const TooltipContainer = ({ children }: PropsWithChildren) => {
  const context = useValue()
  return (
    <TopLevelContext.Provider value={context}>
      {children}
      <div
        ref={context.ref}
        style={{ position: "fixed", zIndex: 100 }}
        data-display-name="tooltip-container"
      />
    </TopLevelContext.Provider>
  )
}

export const useTooltipContainer = () => {
  const context = useContext(TopLevelContext)

  if (context === null) {
    throw new Error(
      "useTooltipContainer is used without a parent <TooltipContainer />."
    )
  }

  return context
}

const useValue = () => {
  const ref = useRef<HTMLDivElement>(null)

  return useMemo(() => ({ ref }), [])
}
