import { ReactComponent as DiplomaLockedIcon } from "assets/icons/diplomaLockedSmall.svg"
import { ReactComponent as DueDateIcon } from "assets/icons/dueDateClock.svg"
import { ReactComponent as OverDueIcon } from "assets/icons/dueDateOverdue.svg"
import { formatDate } from "format"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

type DeadlineVariant = "overdue" | "dueDate"
type DeadlineSize = "small" | "medium"

export const Deadline = ({
  variant,
  size,
  deadline,
  hasDiploma,
}: {
  variant: DeadlineVariant
  size: DeadlineSize
  deadline?: string | undefined | null
  hasDiploma?: boolean
}) => {
  const { t, i18n } = useTranslation()
  const translationKey = getVariantTranslationKey(variant)

  const formattedDeadline = formatDeadlineDate(deadline, i18n.language)
  const Icon = getVariantIcon(variant)
  const title = t(translationKey)

  return (
    <div className={modifiers(s, "deadline", variant, size)}>
      {hasDiploma ? (
        <DiplomaLockedIcon className={s.deadline__diplomaIcon} />
      ) : (
        <Icon className={s.deadline__icon} />
      )}
      <span className={s.deadline__title}>{title}</span>
      {formattedDeadline !== undefined && <span>- {formattedDeadline}</span>}
    </div>
  )
}

const getVariantIcon = (variant: DeadlineVariant) => {
  switch (variant) {
    case "dueDate":
      return DueDateIcon
    default:
      return OverDueIcon
  }
}

const getVariantTranslationKey = (variant: DeadlineVariant) => {
  switch (variant) {
    case "dueDate":
      return "product.DUE_DATE"
    default:
      return "product.OVERDUE"
  }
}

const formatDeadlineDate = (
  date: string | undefined | null,
  locale: string
) => {
  if (!date) return
  return formatDate(date, locale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}
