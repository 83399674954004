import { isPromise } from "./utils/isPromise"

let isRunningQueryAll = false

export const getIsRunningQueryAll = () => isRunningQueryAll

/**
 * useQueryAll enables useQuery to run in parallel
 */
export const useQueryAll = <const T>(runQueries: () => T): T => {
  try {
    isRunningQueryAll = true
    const queryResults = runQueries()

    if (!Array.isArray(queryResults)) {
      throw new Error("useQueryAll must return an array of queries")
    }

    const isSomeDataPending = queryResults.some(isPromise)
    if (isSomeDataPending) {
      throw Promise.all(queryResults)
    }

    return queryResults
  } finally {
    isRunningQueryAll = false
  }
}
