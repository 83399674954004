import { z } from "zod"

export const IncomingMessage = z.union([
  z.object({
    type: z.literal("SET_MINI_MODAL_PRODUCT_ID"),
    payload: z.number(),
  }),

  z.object({
    type: z.literal("REFRESH_PRODUCT_SCORE"),
  }),

  z.object({
    type: z.literal("SET_LANGUAGE"),
    payload: z.object({
      localeCode: z.string(),
    }),
  }),

  z.object({
    type: z.literal("CLOSE_PRODUCT_MODALS"),
  }),

  z.object({
    type: z.literal("SET_PUSH_NOTIFICATIONS_ENABLED"),
    payload: z.boolean(),
  }),

  z.object({
    type: z.literal("SET_IS_SHARED_DEVICE"),
    payload: z.boolean(),
  }),
])
