import {
  combinePaths,
  getQueryParams,
  waitMillis,
  withQueryParams,
} from "utils"
import { FetchError } from "./FetchError"
import { RequestFailedError } from "./RequestFailedError"
import { DEFAULT_RESPONSE_TYPE } from "./constants"
import type { Headers, QueryParams } from "./types"

const { delay: delayQueryParam } = getQueryParams()

export type ResponseType = "text" | "json" | "blob"

export type RunFetchOptions<T, R extends ResponseType> = {
  path: string
  pathPrefix?: string
  baseUrl?: string
  method?: "GET" | "POST" | "DELETE" | "PATCH" | "PUT"
  headers?: Headers
  data?: unknown
  params?: QueryParams
  onError?: (error: FetchError) => any
  transform?: (response: DataFromResponseType<R>) => Promise<T> | T
  responseType?: R
  delay?: string | number
  signal?: AbortSignal
}

export const runFetch = async <
  T = unknown,
  R extends ResponseType = typeof DEFAULT_RESPONSE_TYPE
>(
  options: RunFetchOptions<T, R>
): Promise<T> => {
  const {
    path,
    pathPrefix,
    baseUrl,
    data,
    params,
    onError,
    method = "GET",
    transform,
    delay = delayQueryParam,
    responseType = DEFAULT_RESPONSE_TYPE,
    signal,
  } = options

  if (delay) {
    await waitMillis(Number(delay))
  }

  let url = combinePaths(baseUrl, pathPrefix, path)
  if (params) url = withQueryParams(url, params)

  let status: number | undefined
  let text: string | undefined
  let json: unknown | undefined
  let blob: Blob | undefined

  try {
    const req = new Request(url, {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers: await mergeHeaders(options),
      signal,
    })

    const response = await fetch(req)
    status = response.status

    if (!response.ok) {
      try {
        text = await response.text()
        json = JSON.parse(text)
      } catch (e) {
        console.error(e)
      }
      throw new RequestFailedError()
    }

    if (!transform) return undefined as T

    if (responseType === "text") {
      text = await response.text()
    }
    if (responseType === "json") {
      const responseText = await response.text()
      if (responseText) {
        text = responseText
        json = JSON.parse(responseText)
      }
    }
    if (responseType === "blob") {
      blob = await response.blob()
    }

    let transformData: any = json ?? text ?? blob ?? null
    return await transform(transformData)
  } catch (error) {
    const resolved = resolveError({
      error,
      method,
      url,
      text,
      json,
      status,
    })
    await onError?.(resolved)
    throw resolved
  }
}

const mergeHeaders = async <T, R extends ResponseType>(
  args: RunFetchOptions<T, R>
) => {
  return {
    ...(args.data ? { "Content-Type": "application/json" } : undefined),
    ...args.headers,
  }
}

type ResolveErrorProps = {
  error: unknown
  method: string
  url: string
  status: number | undefined
  text: string | undefined
  json: unknown | undefined
}

const resolveError = ({
  error,
  status,
  url,
  method,
  json,
  text,
}: ResolveErrorProps): FetchError => {
  if (error instanceof FetchError) {
    return error
  }

  if (error instanceof RequestFailedError) {
    return new FetchError({
      url,
      method,
      status,
      text,
      json,
      message: undefined,
      cause: undefined,
    })
  }

  if (error instanceof Error) {
    return new FetchError({
      url,
      method,
      status,
      text,
      json,
      message: error.message,
      cause: error,
    })
  }

  throw new Error(`${error}`, { cause: error })
}

type DataFromResponseType<T> = T extends "text"
  ? string
  : T extends "json"
  ? unknown
  : T extends "blob"
  ? Blob
  : never
