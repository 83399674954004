import { createContext, useContext, useMemo, useState } from "react"

type ContextType = ReturnType<typeof useContextValue>["context"]

export const DialogContext = createContext<ContextType | null>(null)

export const useContextValue = () => {
  const [backgroundElement, setBackgroundElement] =
    useState<HTMLDivElement | null>(null)

  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null)

  const context = useMemo(
    () => ({ modalElement, backgroundElement }),
    [modalElement, backgroundElement]
  )

  return {
    context,
    setBackgroundElement,
    setModalElement,
  }
}

export const useDialogContainerContext = () => {
  const context = useContext(DialogContext)

  if (!context) {
    throw new Error(
      "useDialogContainerContext is used without a parent <DialogContainer />."
    )
  }

  return context
}
