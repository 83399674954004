import productImage from "assets/demo/productImage.svg"
import { mockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof journeyCardFragment>

export const journeyCardFragment = graphql(`
  fragment JourneyCard on Product {
    id
    image
    productType
    title
    hasPlayedXapi

    activityProgress {
      progressCount
      progressOf
    }

    progress {
      mandatory
      starsCount
      starsMax
      certified
    }

    course {
      isAttending
    }
  }
`)

export const mockJourneyCardData = mockData<Data>({
  id: "1",
  image: productImage,
  productType: "pdf",
  title: "Start here",
  hasPlayedXapi: false,
  activityProgress: {
    progressCount: 10,
    progressOf: 15,
  },
  progress: {
    mandatory: false,
    starsCount: 0,
    starsMax: 1,
    certified: false,
  },
  course: {
    isAttending: false,
  },
})
