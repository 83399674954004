import { times } from "lodash-es"
import { Card } from "materia"
import s from "./styles.module.scss"

export const EventWidgetLoading = () => {
  return (
    <Card borderRadius="24">
      <div className={s.skeleton}>
        <div className={s.skeleton__header}>
          <div className={s.skeleton__title} />
          <div className={s.skeleton__arrow} />
        </div>
        <div className={s.skeleton__items}>
          {times(3, (index) => (
            <div className={s.skeleton__item} key={index}>
              <div className={s.skeleton__course}>
                <div className={s.skeleton__date} />
                <div className={s.skeleton__eventTitle} />
              </div>
              <div className={s.skeleton__info} />
            </div>
          ))}
        </div>
      </div>
    </Card>
  )
}
