import { auth } from "auth"
import Plausible from "plausible-tracker"
import { APP_ENV } from "../env"
import { PLAUSIBLE_CONFIG } from "../initialize/initializePlausible"

type TrackEvent = Parameters<ReturnType<typeof Plausible>["trackEvent"]>
export const trackEvent = async (...event: TrackEvent) => {
  if (APP_ENV !== "production") return

  const { companyNameKey } = await auth.getActiveSession()
  const [eventName, options, eventData] = event

  const plausible = Plausible(PLAUSIBLE_CONFIG)

  const optionsWithDefaults = {
    props: {
      companyNameKey,
      ...(options?.props ?? {}),
    },
  }

  plausible.trackEvent(eventName, optionsWithDefaults, eventData)
}
