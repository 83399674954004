import { useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { HiddenHeader } from "../../components/HiddenHeader"
import { usePageTitle } from "../../tools/usePageTitle"
import { Journeys } from "./Journeys"
import s from "./styles.module.scss"

export const JourneyView = () => {
  useScrollToTopOnMount()
  const { t } = useTranslation()
  usePageTitle(t("pageTitle.JOURNEY"))

  return (
    <div className={s.view}>
      <HiddenHeader title={t("navigation.JOURNEY")} tag="h1" />
      <Journeys />
    </div>
  )
}
