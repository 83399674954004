import { exists } from "utils"
import { getConfig } from "./config"
import { deleteAndRevokeSession } from "./deleteAndRevokeSession"
import { redirectToLogin } from "./redirect/redirectToLogin"
import { getSessionsByEnvironment } from "./storage/sessionStorage"
import { getSubdomainFromStorage } from "./storage/subdomainStorage"

export const logoutAndLogin = async () => {
  const session = await findActiveSession()

  if (session) {
    await deleteAndRevokeSession(session)
  }

  return redirectToLogin({
    subdomain: session?.subdomain,
  })
}

export const logoutAndSLORedirect = async () => {
  const session = await findActiveSession()

  if (!session) {
    return redirectToLogin({ subdomain: undefined })
  }

  const responses = await deleteAndRevokeSession(session)

  const sloRedirectLinks = responses
    .map((response) => response.single_log_out_redirect_url)
    .filter(exists)

  if (sloRedirectLinks.length > 1) {
    throw new Error("There should never be more than one revoked session")
  }

  const redirectUrl = sloRedirectLinks[0]

  if (redirectUrl) {
    // We try to open a pop-up window to redirect to SLO URL. If it fails,
    // it means that user's browser blocks pop-ups (for example Safari does it
    // by default) and we will need to open the SLO redirect in the same tab instead.
    // For non-blocking browsers we'll use that newly open window for redirect.
    const windowReference = window.open()

    if (windowReference) {
      windowReference.location = redirectUrl
      redirectToLogin({ subdomain: session.subdomain })
    } else {
      window.location.href = redirectUrl
    }
  }
}

export const logoutAndGetNext = async () => {
  const session = await findActiveSession()

  if (session) {
    await deleteAndRevokeSession(session)
  }

  const { environment } = await getConfig()
  const sessions = await getSessionsByEnvironment(environment)
  const nextSession = sessions[0]

  if (nextSession) {
    return nextSession
  }

  return redirectToLogin({
    subdomain: session?.subdomain,
  })
}

export const refreshLogin = async () => {
  const session = await findActiveSession()

  return redirectToLogin({
    subdomain: session?.subdomain,
    redirectUrl: window.location.href,
  })
}

export const loginNewSession = async () => {
  return redirectToLogin({
    subdomain: undefined,
  })
}

const findActiveSession = async () => {
  const { environment } = await getConfig()
  const sessions = await getSessionsByEnvironment(environment)
  const subdomain = getSubdomainFromStorage()

  return sessions.find((session) => session.subdomain === subdomain)
}
