import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useQueryParam } from "hooks"
import { leaderboard } from "leaderboard-api"
import { Loader } from "materia"
import { useTranslation } from "react-i18next"
import { QueryBoundary, WhenVisible } from "utility-components"
import { type Custom, custom } from "../../bonzai/bonzai"
import { getNextPageParamV1 } from "../../bonzai/getNextPageParamV1"
import { useFormatUser } from "../../bonzai/useFormatUser"
import { LeaderboardEntry } from "../../components/LeaderboardEntry"
import { getMaxScore } from "../../dataUtilities/getMaxScore"
import { getProductLink } from "../../dataUtilities/productDataUtilities"
import { usePickText } from "../../i18n/usePickText"
import { ConfigError } from "../../tools/ConfigError"
import { LeaderboardView } from "./LeaderboardView"

type LeaderboardScope = Custom["getLeaderboardScopes"][number]

export const LeaderboardViewLoader = () => (
  <QueryBoundary fallback={<LeaderboardView.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const scopes = custom.getLeaderboardScopes.useSuspenseQuery([])
  const firstScope = scopes[0]

  if (firstScope === undefined) {
    throw new ConfigError("No leaderboard scopes")
  }

  const [scope = firstScope.value, setScope] = useQueryParam("scope")

  return (
    <LeaderboardView>
      <Scopes scopes={scopes} value={scope} onChange={setScope} />
      <QueryBoundary fallback={<Loader />}>
        <Leaderboards scope={scope} />
      </QueryBoundary>
    </LeaderboardView>
  )
}

type ScopesProps = {
  value: string
  onChange: (value: string) => void
  scopes: LeaderboardScope[]
}
const Scopes = ({ scopes, value, onChange }: ScopesProps) => {
  const { t } = useTranslation()

  const scopeElements = scopes.map((scope) => (
    <LeaderboardView.Scope value={scope.value} key={scope.value}>
      {scope.name}
    </LeaderboardView.Scope>
  ))

  return (
    <LeaderboardView.Scopes
      value={value}
      onChange={onChange}
      header={t("leaderboard.ALL_LEADERBOARDS")}
    >
      {scopeElements}
    </LeaderboardView.Scopes>
  )
}

type LeaderboardsProps = {
  scope: string
}
const Leaderboards = ({ scope }: LeaderboardsProps) => {
  const { data, fetchNextPage, isFetchingNextPage } = useLeaderboardsData()

  const pages = data.pages.map((page, index) => (
    <LeaderboardPage
      key={index}
      scope={scope}
      productIds={page.data}
      fetchNextPage={fetchNextPage}
      isFirstPage={index === 0}
      isFetchingNextPage={isFetchingNextPage}
    />
  ))

  return (
    <LeaderboardView.Leaderboards>
      {pages}
      <Loader isVisible={isFetchingNextPage} />
    </LeaderboardView.Leaderboards>
  )
}

type LeaderboardPageProps = {
  productIds: number[]
  scope: string
  fetchNextPage: () => void
  isFirstPage: boolean
  isFetchingNextPage: boolean
}
const LeaderboardPage = ({
  productIds,
  scope,
  fetchNextPage,
  isFirstPage,
  isFetchingNextPage,
}: LeaderboardPageProps) => {
  const leaderboards = productIds.map((id) => (
    <Leaderboard key={id} productId={id} scope={scope} />
  ))

  return (
    <QueryBoundary isSuspense={!isFirstPage} fallback={<Loader />}>
      {leaderboards}

      <WhenVisible
        key={scope}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </QueryBoundary>
  )
}

type LeaderboardProps = {
  productId: number
  scope: string
}
const Leaderboard = ({ productId, scope }: LeaderboardProps) => {
  const { t } = useTranslation()
  const pickText = usePickText()

  const [highScores, product] = useLeaderboardData(productId, scope)
  const formatUser = useFormatUser()

  if (highScores.entries.length === 0) return null

  const maxScore = getMaxScore(highScores.entries)
  const showMyEntry = highScores.my_entry.value > 0

  const entries = highScores.entries.map((entry, index) => (
    <LeaderboardEntry
      key={index}
      image={entry.user.image}
      imageDescription={entry.user.image_title["en-US"]}
      name={formatUser(entry.user)}
      rank={entry.rank}
      score={entry.value}
      maxScore={maxScore}
      isFirst={index === 0}
    />
  ))

  return (
    <LeaderboardView.Leaderboard
      link={{ to: getProductLink(product.id, product.product_type.identifier) }}
      title={pickText(product.title)}
      linkText={t("leaderboard.SEE_ALL")}
    >
      {entries}
      {showMyEntry && (
        <LeaderboardEntry
          image={highScores.my_entry.user.image}
          imageDescription={highScores.my_entry.user.image_title["en-US"]}
          isFirst={false}
          maxScore={maxScore}
          name={formatUser(highScores.my_entry.user)}
          rank={highScores.my_entry.rank}
          score={highScores.my_entry.value}
        />
      )}
    </LeaderboardView.Leaderboard>
  )
}

const useLeaderboardsData = () => {
  return custom.getPlayableProductIds.useSuspenseInfiniteQuery(
    [{ per_page: 6 }],
    {
      initialPageParam: { page: 1 },
      getNextPageParam: getNextPageParamV1,
    }
  )
}

const useLeaderboardData = (product_id: number, scope: string) => {
  return useQueryAll(() => [
    leaderboard.getHighScores.useSuspenseQuery([
      { product_id, scope, length: 3 },
    ]),
    v3.getProduct.useSuspenseQuery([product_id], {
      select: (res) => res.data,
    }),
  ])
}
