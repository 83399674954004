import { mockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof productModalButtonsFragment>

export const productModalButtonsFragment = graphql(`
  fragment ProductModalButtons on Product {
    id
    productType
    contentLink
    openExternalBrowser
    scormStrictScoring
    webglResponsive
    supportedPlatforms
    useTransferToken
    hasVppCodes

    playLinks(platform: $platform) {
      webglLink
      launcherLink
      appLink
      appStoreLink
    }

    productUploads {
      nodes {
        url
        locale
      }
    }

    localeContentLinks {
      nodes {
        link
        locale
      }
    }

    # Only for sending to skills

    descriptionLong
    image
    title
    companyId
    showNavigationBar
  }
`)

export const mockProductModalButtonsData = mockData<Data>({
  contentLink: "link",
  hasVppCodes: false,
  localeContentLinks: {
    nodes: [
      { locale: "nb-NO", link: "" },
      { locale: "fr-FR", link: "" },
    ],
  },
  productUploads: {
    nodes: [
      { locale: "nb-NO", url: "" },
      { locale: "fr-FR", url: "" },
    ],
  },
  scormStrictScoring: false,
  supportedPlatforms: ["Android", "MacOS", "WebGL", "Windows", "iOS"],
  webglResponsive: false,
  id: "1",
  openExternalBrowser: false,
  playLinks: {
    appLink: "link",
    launcherLink: "link",
    webglLink: "link",
    appStoreLink: "link",
  },
  productType: "pdf",
  useTransferToken: false,
  companyId: "1",
  descriptionLong: null,
  image: "",
  title: null,
  showNavigationBar: false,
})
