import { mockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof navigationFragment>

export const navigationFragment = graphql(`
  fragment Navigation on Company {
    portalFeatureFlags
    portalLandingPage
  }
`)

export const mockNavigationData = mockData<Data>({
  portalFeatureFlags: ["enable_learning_journey", "enable_portal_news"],
  portalLandingPage: "for_you",
})
