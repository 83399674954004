import { Transition } from "@headlessui/react"
import type { HTMLAttributes } from "react"
import s from "./styles.module.scss"

export const MenuAnimation = ({
  isOpen,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean
}) => {
  return (
    <Transition
      show={isOpen}
      enter={s["animation--enter"]}
      enterFrom={s["animation--enterFrom"]}
      enterTo={s["animation--enterTo"]}
      leave={s["animation--leave"]}
      leaveFrom={s["animation--leaveFrom"]}
      leaveTo={s["animation--leaveTo"]}
      {...props}
    />
  )
}
