import { useTranslation } from "react-i18next"
import { FiestaLink } from "utility-components"
import { FilteredProducts } from "../../components/FilteredProducts"
import { HiddenHeader } from "../../components/HiddenHeader"
import type { DeadlineStatus } from "../../graphql"
import { IncompleteFilter } from "./IncompleteFilter"
import s from "./styles.module.scss"

export const FilterView = ({
  isIncomplete,
  deadlineStatus,
  isMandatory,
}: {
  isIncomplete: boolean | undefined
  isMandatory: boolean | undefined
  deadlineStatus: DeadlineStatus | undefined
}) => {
  const { t } = useTranslation()

  return (
    <>
      {isMandatory ? (
        <MandatoryHeader />
      ) : (
        <IncompleteFilter
          isIncomplete={isIncomplete}
          deadlineStatus={deadlineStatus}
          isMandatory={isMandatory}
        />
      )}
      <HiddenHeader title={t("product.PRODUCTS")} tag="h2" />
      <FilteredProducts
        input={{
          variant: isIncomplete ? "incomplete" : "mandatory",
          deadlineStatus,
        }}
      />
    </>
  )
}

const MandatoryHeader = () => {
  const { t } = useTranslation()

  return (
    <div className={s.mandatoryHeader}>
      <h1 className={s.mandatoryHeader__title}>{t("user.MANDATORY")}</h1>
      <FiestaLink
        to="/for-you"
        search={{}}
        className={s.mandatoryHeader__clear}
      >
        {t("product.CLEAR_FILTER")}
      </FiestaLink>
    </div>
  )
}
