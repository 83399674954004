import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["products"]["nodes"][number]
export type Course = NonNullable<Product["course"]>

export const query = graphql(`
  query EventWidget {
    currentCompany {
      portalFeatureFlags
    }
    products(limit: 3, input: { variant: events }) {
      nodes {
        id
        title
        course {
          isAttending
          startDatetime
        }
      }
    }
  }
`)

export const mockEventWidgetData = mockData<Data>({
  currentCompany: {
    portalFeatureFlags: [],
  },
  products: {
    nodes: times(3, (index) => ({
      id: index.toString(),
      title: "Event title",
      course: {
        isAttending: false,
        startDatetime: "2022-01-01T00:00:00Z",
      },
    })),
  },
})

registerMockData(query, mockEventWidgetData())
