import { graphql, graphqlRequest } from "."

const mutation = graphql(`
  mutation CreatePlaythrough($productId: ID!) {
    playthroughCreate(input: { productId: $productId }) {
      success
    }
  }
`)

export const createPlaythrough = async (productId: string) => {
  await graphqlRequest({ query: mutation, variables: { productId } })
}
