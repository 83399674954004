import { useEffect } from "react"
import { useConfettiBannerStore } from "./store"

export const useBlockConfettiBanner = () => {
  useEffect(() => {
    const { setIsBlocked } = useConfettiBannerStore.getState()

    setIsBlocked(true)

    return () => setIsBlocked(false)
  }, [])
}
