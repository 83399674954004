import { v3, type V3 } from "backoffice-api"
import { ConfigError } from "../tools/ConfigError"

type EventDate = V3["getProductCourse"]["data"]

export const toggleWaitingList = async (event: EventDate) => {
  optimisticUpdate(event)
  await apiUpdate(event)
}

const optimisticUpdate = ({ id }: EventDate) => {
  v3.getProduct.setQueriesData([], (product) => {
    const event = product.data.courses?.find((event) => event.id === id)
    if (!event) return

    const { waiting_list_position, is_on_waiting_list, waiting_list_count } =
      event

    event.is_on_waiting_list = !is_on_waiting_list
    event.waiting_list_position = is_on_waiting_list
      ? waiting_list_position - 1
      : waiting_list_count + 1
    event.waiting_list_count = is_on_waiting_list
      ? waiting_list_count - 1
      : waiting_list_count + 1
  })
}

const apiUpdate = async (event: EventDate) => {
  const waitingListGroupId = await getWaitingListGroupId(event.id)

  if (event.is_on_waiting_list) {
    await v3.removeMyUserFromGroup(waitingListGroupId)
  } else {
    await v3.addMyUserToGroup(waitingListGroupId)
  }
}

const getWaitingListGroupId = async (courseId: string) => {
  const course = await v3.getProductCourse(courseId, { include: ["group"] })

  const parentGroupId = course.data.group?.id

  if (parentGroupId === undefined) {
    throw new ConfigError("Missing event group")
  }

  const parentGroup = await v3.getGroup(parentGroupId, {
    include: ["children"],
  })

  const waitingListGroupId = parentGroup.data.children?.[0]?.id

  if (waitingListGroupId === undefined) {
    throw new ConfigError("Missing event waiting list child group")
  }

  return waitingListGroupId
}
