import * as Sentry from "@sentry/react"
import { type ResultOf } from "graphql-mock"
import { graphql, graphqlRequest } from "../../graphql"

type Data = ResultOf<typeof query>
type ProductProgress = Data["product"]["progress"] | undefined

export type ProgressChange =
  | { type: "high_score"; productId: string; highScore: number }
  | { type: "certified"; productId: string }

const query = graphql(`
  query CurrentProductProgress($productId: ID!) {
    product(id: $productId) {
      progress {
        certified
        highScore
        starsCount
      }
    }
  }
`)

const productProgressMap = new Map<string, ProductProgress>()

export const checkForProgressChange = async (
  productId: string
): Promise<ProgressChange | undefined> => {
  const { before, after } = await updateProductProgress(productId)

  if (before === undefined || after === undefined) return

  if (after.certified && !before.certified) {
    return { type: "certified", productId }
  }

  if (after.highScore > before.highScore) {
    return { type: "high_score", productId, highScore: after.highScore }
  }
}

const updateProductProgress = async (productId: string) => {
  const before = productProgressMap.get(productId)

  const result = await apiQueryCurrentProductProgress(productId)
  const after = result?.product.progress

  productProgressMap.set(productId, after)

  return { before, after }
}

const apiQueryCurrentProductProgress = async (productId: string) => {
  try {
    return await graphqlRequest({ query, variables: { productId } })
  } catch (error) {
    console.error("Error on query product progress: ", error)
    Sentry.setTag("feature", "queryCurrentProductProgress")
    Sentry.captureException(error)
  }
}
