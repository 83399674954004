import checkmark from "assets/icons/checkmark.svg"
import chevronIconWhite from "assets/icons/chevronIconWhite.svg"
import lockedJourneyIcon from "assets/icons/lockedJourneyIcon.svg"
import { useHasValueChanged } from "hooks"
import { ProgressCircle } from "materia"
import { modifiers } from "ui-tools"
import { toPercent } from "utils"
import type { Data } from "../Journey/gql"
import s from "./styles.module.scss"

export const JourneyHeader = ({
  data,
  isOpen,
  onClick,
}: {
  data: Data
  isOpen: boolean
  onClick: () => void
}) => {
  const { progress, isLocked, title } = data

  const { certifiedCount, certifiedMax } = progress
  const progressPercentage = toPercent(certifiedCount, certifiedMax)
  const enableAnimations = useHasValueChanged(isLocked)
  const ariaLabel = `Journey - ${title} - ${progressPercentage}% progress`

  return (
    <div className={modifiers(s, "header", { isOpen, enableAnimations })}>
      <button
        className={s.header__button}
        onClick={onClick}
        aria-expanded={isOpen}
        aria-label={ariaLabel}
      >
        <div className={s.header__status}>
          <Progress data={data} />
          <Locked data={data} />
        </div>

        <h2 className={s.header__title}>{title}</h2>
        <img className={s.header__toggle} src={chevronIconWhite} alt="" />
      </button>
    </div>
  )
}

const Progress = ({ data }: { data: Data }) => {
  const { progress } = data
  const { certifiedCount, certifiedMax, isCertified } = progress

  if (certifiedMax === 0) {
    return <div className={s.header__progress} />
  }

  if (isCertified) {
    return <img className={s.header__certified} src={checkmark} alt="" />
  }

  return (
    <div className={s.header__progress}>
      <ProgressCircle
        variant="white"
        backgroundColor="black"
        fillBackground
        value={certifiedCount}
        maxValue={certifiedMax}
        stroke={7}
      >
        {toPercent(certifiedCount, certifiedMax)}%
      </ProgressCircle>
    </div>
  )
}

const Locked = ({ data }: { data: Data }) => {
  const { lockedBy, isLocked } = data

  const enableAnimations = useHasValueChanged(isLocked)

  const lockedByTitle = lockedBy.nodes
    .map((category) => category.title)
    .join(", ")

  const title = lockedByTitle
    ? `Complete ${lockedByTitle} to unlock journey`
    : undefined

  return (
    <div
      className={modifiers(s, "header__lockedContainer", {
        isLocked,
        enableAnimations,
      })}
    >
      <img
        title={title}
        className={"header__lockedContainerIcon"}
        src={lockedJourneyIcon}
        alt=""
      />
    </div>
  )
}
