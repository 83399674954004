import { Dialog } from "materia"
import s from "./styles.module.scss"

export const NewsPostViewLoading = () => {
  return (
    <Dialog ariaLabel="News post" onClose={() => {}}>
      <div className={s.view}>
        <div className={s.skeleton}>
          <div className={s.skeleton__content}>
            <div className={s.skeleton__image} />
            <div className={s.skeleton__title} />
            <ul className={s.skeleton__description}>
              <li className={s.skeleton__descriptionItem} />
              <li className={s.skeleton__descriptionItem} />
              <li className={s.skeleton__descriptionItem} />
              <li className={s.skeleton__descriptionItem} />
              <li className={s.skeleton__descriptionItem} />
              <li className={s.skeleton__descriptionItem} />
              <li className={s.skeleton__descriptionItem} />
            </ul>
          </div>
          <div className={s.skeleton__bottom} />
        </div>
      </div>
    </Dialog>
  )
}
