import type { ShowErrorPopupProps } from "@attensi/error-popup"
import { getQueryParams, never } from "utils"

const errorPopupPromise = import("@attensi/error-popup").catch((error) => {
  console.error(error)
  return never()
})

export const showErrorPopup = async (props: ShowErrorPopupProps) => {
  // [TODO] Hide error popup behind a query param temporarily
  // https://jira.attensi.com/browse/WEB-18429
  const queryParams = getQueryParams()
  const enableDebug =
    queryParams.enable_debug === "true" ||
    process.env.NODE_ENV === "development"
  if (!enableDebug) return

  try {
    const { showErrorPopup } = await errorPopupPromise
    showErrorPopup({ skipLandingPage: true, ...props })
  } catch (error) {
    console.error(error)
  } finally {
    console.error(props.error)
  }
}
