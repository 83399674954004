import { Menu, Transition } from "@headlessui/react"
import { ReactComponent as Chevron } from "assets/icons/chevronIcon.svg"
import {
  Fragment,
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type PropsWithChildren,
} from "react"
import { modifiers } from "ui-tools"
import { Card } from "../Card"
import s from "./styles.module.scss"

/**
 * Docs: https://headlessui.com/v1/react/menu
 */
export const MenuList = ({ children }: PropsWithChildren) => {
  return (
    <Menu as="div" className={s.menuList}>
      {children}
    </Menu>
  )
}

const Button = ({ children }: PropsWithChildren) => (
  <Menu.Button as={Fragment}>
    {({ open }) => (
      <button className={modifiers(s, "button", { open })}>
        <div className={s.button__content}>{children}</div>
        <Chevron className={s.button__icon} />
      </button>
    )}
  </Menu.Button>
)

const CustomButton = ({ children }: PropsWithChildren) => (
  <Menu.Button as={Fragment}>
    {({ open }) => (
      <button className={modifiers(s, "customButton", { open })}>
        {children}
      </button>
    )}
  </Menu.Button>
)

const Items = ({ children }: PropsWithChildren) => (
  <Animation>
    <Menu.Items>
      <Card>{children}</Card>
    </Menu.Items>
  </Animation>
)

const ButtonItem = ({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Menu.Item as={Fragment}>
    {({ active }) => (
      <button {...props} className={modifiers(s, "option", { active })}>
        {children}
      </button>
    )}
  </Menu.Item>
)

const ExternalLinkItem = ({
  children,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <Menu.Item as={Fragment}>
      {({ active }) => (
        <a
          {...props}
          rel="noreferrer"
          target="_blank"
          className={modifiers(s, "option", { active })}
        >
          {children}
        </a>
      )}
    </Menu.Item>
  )
}

const ChevronIcon = () => <Chevron className={s.customButton__icon} />

MenuList.Button = Button
MenuList.CustomButton = CustomButton
MenuList.Items = Items
MenuList.ButtonItem = ButtonItem
MenuList.ExternalLinkItem = ExternalLinkItem
MenuList.ChevronIcon = ChevronIcon

/*
 * Docs: https://headlessui.com/react/transition
 */
const Animation = ({ children }: PropsWithChildren) => (
  <Transition
    className={s.animation}
    enter={s["animation--enter"]}
    enterFrom={s["animation--enterFrom"]}
    enterTo={s["animation--enterTo"]}
    leave={s["animation--leave"]}
    leaveFrom={s["animation--leaveFrom"]}
    leaveTo={s["animation--leaveTo"]}
  >
    {children}
  </Transition>
)
