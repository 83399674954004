import leaderboardIcon from "assets/icons/leaderboardColorIcon.svg"
import { Card } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import s from "./LeaderboardWidget.module.scss"

type LeaderboardWidgetProps = {
  ariaLabel: string
  link: FiestaLinkProps
  children: ReactNode
}
export const LeaderboardWidget = ({
  ariaLabel,
  link,
  children,
}: LeaderboardWidgetProps) => (
  <FiestaLink {...link} ariaLabel={ariaLabel}>
    <Card borderRadius="24">
      <div className={s.widget}>{children}</div>
    </Card>
  </FiestaLink>
)

type HeaderProps = {
  title: string
  caption: string
}
const Header = ({ title, caption }: HeaderProps) => (
  <div className={s.header}>
    <img className={s.header__icon} src={leaderboardIcon} alt="" />
    <h2>
      <div className={s.header__caption}>{caption}</div>
      <div className={s.header__title}>{title}</div>
    </h2>
  </div>
)

const Entries = ({ children }: PropsWithChildren) => (
  <ul className={s.entries}>{children}</ul>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton} />
  </Card>
)

LeaderboardWidget.Header = Header
LeaderboardWidget.Entries = Entries
LeaderboardWidget.Skeleton = Skeleton
