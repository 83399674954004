import { DEVICE_PLATFORM } from "../constants"

export const getStaticLauncherLink = () => {
  switch (DEVICE_PLATFORM) {
    case "MacOS":
      return "https://webcontent.attensi.com/download/attensi-launcher.pkg"
    case "Windows":
    default:
      return "https://webcontent.attensi.com/download/attensi-launcher.msi"
  }
}
