import { auth } from "auth"
import type { FetchError } from "fetcher"
import {
  API_TOKEN,
  APP_ENV,
  AUTH_URL,
  BACKOFFICE_API_URL,
  LOGIN_URL,
} from "../env"

type Config = {
  storybookMode?: boolean
}
export const initializeAuth = async ({ storybookMode }: Config = {}) => {
  auth.initializeAuth({
    authUrl: AUTH_URL,
    loginUrl: LOGIN_URL,
    backofficeUrl: BACKOFFICE_API_URL,
    apiToken: API_TOKEN,
    subdomain: storybookMode ? undefined : auth.getSubdomainFromPath(),
    redirectStrategy: storybookMode ? "href" : undefined,
    environment: storybookMode ? "test" : APP_ENV,
    locale: undefined,
  })

  await revalidateExistingSession()
}

/**
 * If there's an existing access token in local storage, it might've expired.
 * Without a check on mount, the app will throw a bunch of unhandled 401s
 * before redirecting to login. Revalidating the session on mount prevents it
 */
const revalidateExistingSession = async () => {
  const { accessToken } = await auth.getActiveSession()
  try {
    await auth.accessTokenToTransferKey(accessToken)
  } catch (error: unknown) {
    if ((error as FetchError).status === 401) {
      await auth.refreshLogin()
    }
  }
}
