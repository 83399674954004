import s from "./styles.module.scss"

export const NewsWidgetMobileLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__image} />
      <div className={s.skeleton__wrapper}>
        <div className={s.skeleton__title} />
        <div className={s.skeleton__description} />
      </div>
      <div className={s.skeleton__arrow} />
    </div>
  )
}
