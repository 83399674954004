import { useNavigate } from "@tanstack/react-location"
import closeIcon from "assets/icons/closeIcon.svg"
import { ReactComponent as LikeIcon } from "assets/icons/likeIcon.svg"
import { v3 } from "backoffice-api"
import { useGraphqlQuery } from "graphql-mock"
import { useScrollToTopOnMount } from "hooks"
import { Dialog } from "materia"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { sanitizeHtml } from "ui-tools"
import { ErrorPopup, FiestaImage, FiestaLink } from "utility-components"
import { NewsPostLikeButton } from "../../components/NewsPostLikeButton"
import { getElapsedTime } from "../../dataUtilities/getElapsedTime"
import { usePageTitle } from "../../tools/usePageTitle"
import { query, type NewsPost } from "./gql"
import { NewsPostViewLoading } from "./loading"
import s from "./styles.module.scss"

export const NewsPostView = ({ id }: { id: string }) => {
  const { data, error, isPending } = useGraphqlQuery(query, { id })
  useScrollToTopOnMount()

  const navigate = useNavigate()
  const closeRoute = "/news"

  useReportNewsView(id)
  usePageTitle(data?.newsPost.headline)

  if (isPending) return <NewsPostViewLoading />
  if (error) return <ErrorPopup error={error} />

  const { newsPost } = data

  const onClose = () => {
    navigate({ to: closeRoute })
  }

  return (
    <Dialog ariaLabel="News post" onClose={onClose}>
      <div className={s.view}>
        <CloseButton closeRoute={closeRoute} />
        <Content newsPost={newsPost} />
        <Bottom newsPost={newsPost} />
      </div>
    </Dialog>
  )
}

const Content = ({ newsPost }: { newsPost: NewsPost }) => {
  const { body, headline, image } = newsPost
  return (
    <div className={s.content}>
      <FiestaImage
        className={s.content__image}
        src={image ?? ""}
        alt="News post"
        sizes="(width <= small) 100vw, 50vw"
      />
      <h1 className={s.content__title}>{headline}</h1>
      <div
        className={s.content__description}
        dangerouslySetInnerHTML={sanitizeHtml(body ?? "", "allow-links")}
      />
    </div>
  )
}

const Bottom = ({ newsPost }: { newsPost: NewsPost }) => {
  const { i18n } = useTranslation()
  const { id, isLiked, likeCount, updatedAt } = newsPost
  return (
    <div className={s.bottom}>
      <NewsPostLikeButton newsPostId={id} isLiked={isLiked} />
      <div className={s.bottom__likes}>
        <LikeIcon aria-label="Likes" role="img" />
        {likeCount}
      </div>
      <div className={s.bottom__date}>
        {getElapsedTime({
          date: updatedAt,
          locale: i18n.language,
        })}
      </div>
    </div>
  )
}

const CloseButton = ({ closeRoute }: { closeRoute: string }) => (
  <FiestaLink className={s.closeButton} to={closeRoute}>
    <img className={s.closeButton__icon} src={closeIcon} alt="Close" />
  </FiestaLink>
)

const useReportNewsView = (news_post_id: string) => {
  useEffect(() => {
    v3.createNewsPostReaction({
      news_post_id,
      reaction_type: "view",
    }).catch(console.warn) // Throws an error when the user has already viewed the post
  }, [news_post_id])
}
