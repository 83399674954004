import { auth, useAuth } from "auth"
import { Loader } from "materia"
import { useTranslation } from "react-i18next"
import { ErrorPopup } from "utility-components"
import s from "./styles.module.scss"

export const CompanyPicker = () => {
  return (
    <div className={s.menu}>
      <Companies />
      <Bottom />
    </div>
  )
}

const Companies = () => {
  const { activeSession, sessions, isPending, error } = useAuth()

  if (isPending) return <Loader />
  if (error) return <ErrorPopup error={error} />

  const nonActiveSessions = sessions.filter(
    (session) => activeSession.accessToken !== session.accessToken
  )

  return (
    <ul className={s.menu__companies}>
      {nonActiveSessions.map((session, index) => (
        <Company
          key={index}
          companyName={session.companyNameKey}
          companyLogo={session.logo}
          href={`/${session.subdomain}`}
        />
      ))}
    </ul>
  )
}

const Bottom = () => {
  const { t } = useTranslation()

  return (
    <div className={s.menu__bottom}>
      <button className={s.menu__add} onClick={() => auth.loginNewSession()}>
        {t("sessions.ADD_SESSION")}
      </button>
    </div>
  )
}

const Company = ({
  companyLogo,
  companyName,
  href,
}: {
  companyName: string
  companyLogo: string
  href: string
}) => {
  return (
    <li className={s.menu__companyListItem}>
      <a className={s.menu__company} title={companyName} href={href}>
        <img className={s.menu__logo} src={companyLogo} alt="" />
      </a>
    </li>
  )
}
