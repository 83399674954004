import { v3 } from "backoffice-api"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { KpiSalesHeader, KpiSalesHeaderSkeleton } from "./KpiSalesHeader"

export const KpiSalesHeaderLoader = () => (
  <QueryBoundary fallback={<KpiSalesHeaderSkeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t } = useTranslation()

  const { data } = v3.getMyKpiSales.useSuspenseQuery([{ months: 13 }])

  if (!data) return null

  const { current_month_count, year_count } = data

  return (
    <KpiSalesHeader>
      <KpiSalesHeader.YourSales
        label={t("kpi.YOUR_SALES")}
        link={{ to: "kpi-sales" }}
      />
      <KpiSalesHeader.Summary>
        <KpiSalesHeader.SummaryItem
          label={t("kpi.THIS_YEAR")}
          value={year_count}
        />
        <KpiSalesHeader.SummaryItem
          label={t("kpi.THIS_MONTH")}
          value={current_month_count.value}
        />
      </KpiSalesHeader.Summary>
    </KpiSalesHeader>
  )
}
