import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query IncompleteFilter {
    userProgressSummary {
      productCompleteCount
      productCompleteMax
    }
    deadlineProgressSummary {
      dueCount
      overdueCount
    }
  }
`)

export const mockIncompleteFilterData = mockData<Data>({
  userProgressSummary: {
    productCompleteCount: 10,
    productCompleteMax: 66,
  },
  deadlineProgressSummary: {
    dueCount: 2,
    overdueCount: 1,
  },
})

registerMockData(query, mockIncompleteFilterData())
