import { type V3, v3 } from "backoffice-api"

type ActivityItem = V3["getActivityItems"]["data"][number]

export const toggleActivityItem = async (
  productId: number,
  activityItem: ActivityItem
) => {
  optimisticUpdate(productId, activityItem)
  await apiUpdate(productId, activityItem)
  invalidate(productId)
}

const optimisticUpdate = (productId: number, { id, done }: ActivityItem) => {
  v3.getActivityItems.setQueriesData([productId], (data) => {
    const item = data.data.find((item) => item.id === id)
    if (!item) return

    item.done = !done
  })
}

const apiUpdate = async (productId: number, { id, done }: ActivityItem) => {
  await v3.updateActivityItem({
    product_id: productId,
    activity_item_id: id,
    done: !done,
  })
}

const invalidate = (productId: number) => {
  v3.getActivityProgress.invalidateQueries([productId])
}
