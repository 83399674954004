import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query NewsWidgetMobile {
    currentCompany {
      portalFeatureFlags
    }
    newsPosts(limit: 1) {
      nodes {
        id
        headline
        image
      }
    }
  }
`)

export const mockNewsWidgetMobileData = mockData<Data>({
  currentCompany: {
    portalFeatureFlags: ["enable_portal_news"],
  },
  newsPosts: {
    nodes: [
      {
        id: "0",
        headline: "News item text that is very long",
        image: productImage,
      },
    ],
  },
})

registerMockData(query, mockNewsWidgetMobileData())
